<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="10" offset-md="1" lg="10" offset-lg="1">
        <v-card rounded="xl" outlined class="text-center pa-md-4">
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="mt-0 mt-md-4 mb-6 mb-md-0 ">
                <img class="hidden-sm-and-down" src="../../static/img/sdg_logo.png" width="50%" alt="logo"/>
                <div :class="$vuetify.breakpoint.smAndDown ? 'text-h6' : 'text-h5'" class="pb-1 pt-2 pt-md-6">{{ $t('bewerbungsportal') }}</div>
                <div :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4'" class="font-weight-bold pb-2 pb-md-6">SDG-Award</div>
              </v-col>
              <v-col cols="12" md="6" class="mt-md-6">
                <v-card outlined class="mb-8">
                  <v-tabs grow v-model="tab" class="mb-0">
                    <v-tab to="/anmelden">{{ $t('login.anmeldung') }}</v-tab>
                    <v-tab>{{ $t('login.registrierung') }}</v-tab>
                  </v-tabs>
                </v-card>
                <v-text-field name="email" :loading="loading" :label="$t('login.emailadresse')" id="email" v-model="email" type="email" outlined dense></v-text-field>
                <v-text-field name="password" :label="$t('login.passwort')" :hint="$t('login.passwortWeak')" id="password" v-model="password" type="password" outlined dense></v-text-field>
                <v-text-field name="confirmPassword" :label="$t('login.passwortbestaetigen')" id="confirmPassword" v-model="confirmPassword" type="password" :rules="[comparePasswords]" outlined dense></v-text-field>
                <v-btn block @click="onSignup()" class="primary" :loading="loadingBtn">
                  {{ $t('login.registrieren') }}
                </v-btn>
                <span v-if="errorMessage.code !== undefined">
                  <v-alert class="mt-4" v-if="errorMessage.code === 'auth/email-already-in-use'" color="primary" border="left" outlined dismissible>
                    {{ $t('login.error.email-already-in-use') }}
                  </v-alert>
                  <v-alert class="mt-4" v-if="errorMessage.code === 'auth/weak_password'" color="primary" border="left" outlined dismissible>
                    {{ $t('login.error.weak_password') }}
                  </v-alert>
                  <v-alert class="mt-4" v-if="(errorMessage.code !== 'auth/email-already-in-use') && (errorMessage.code !== 'auth/weak_password')" color="primary" border="left" outlined dismissible>
                    {{ $t('login.error.else2') }} {{ errorMessage.code }} | {{ errorMessage.message }}
                  </v-alert>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        tab: 1,
        loading: false,
        loadingBtn: false,
        email: '',
        password: '',
        confirmPassword: '',
        checkMailValue: { status: null },
        error: {},
        mailVorhanden: false
      }
    },
    mounted () {
      this.$store.dispatch('user/clearError')
    },
    computed: {
      comparePasswords () {
        return this.password !== this.confirmPassword ? 'Passwörter stimmen nicht überein' : ''
      },
      user () {
        return this.$store.state.user.user
      },
      errorMessage () {
        return this.$store.state.user.error
      }
    },
    watch: {
      user () {
        let user = this.$firebase.auth().currentUser
        if (!user.emailVerified) {
          user.sendEmailVerification().then(function () {
            console.log('Verification Mail send')
          }).catch(error => {
            console.log(error)
          })
        }
        if (this.$firebase.auth().currentUser) {
          this.$router.push('/')
        }
      }
    },
    methods: {
      onSignup () {
        // this.loadingBtn = true
        console.log(this.email)
        console.log(this.password)
        this.$store.dispatch('user/signUserUp', {email: this.email, password: this.password})
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>
