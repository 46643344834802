import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import App from './App.vue'
import router from './router'
import firebase from '../firebase.js'
Vue.prototype.$firebase = firebase

import DateFilter from './filters/date'
import UserFilter from './filters/user'
import MoneyFilter from './filters/money'

Vue.filter('date', DateFilter)
Vue.filter('user', UserFilter)
Vue.filter('money', MoneyFilter)

import VueI18n from 'vue-i18n';

import de from './locales/de.json';
import en from './locales/en.json';
import fr from './locales/fr.json';

import { store } from './store'

Vue.use(Vuetify)

// ****************************************************************
// Import globale Variablen und Funktionen
// ****************************************************************
import vars from './main_variablen.js'
Vue.prototype.$vars = vars

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  defaults: {
    VBtn: {
      style: 'text-transform: none;',
      type: 'outlined'
    }
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#203569',
        secondary: '#424242',
        accent: '#82B1FF',
        sdgUnternehmen: '#4395D4',
        sdgStaedte: '#609D47',
        sdgJugend: '#FC6A26',
        sdgMedien: '#DB1468',
        background: '#203569'
      },
      dark: {
        primary: '#2196F3',
        secondary: '#757575',
        accent: '#90CAF9'
      }
    }
  }
})

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de', // Standardmäßig Deutsch anzeigen
  messages: {
    de,
    en,
    fr
  }
});

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
  created () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('user/autoSignIn', user)
      }
      this.$store.dispatch('personen/loadPersonen')
      this.$store.dispatch('bewerbungen/loadBewerbungen')
      this.$store.dispatch('bewertungen/loadBewertungen')
    })
  }
}).$mount('#app')
