import Vue from 'vue'
import Router from 'vue-router'

import authGuard from './auth-guard'

import userRegistrieren from '../components/userRegistrieren.vue'
import userVerifiziereMail from '../components/userVerifiziereMail.vue'
import userAnmelden from '../components/userAnmelden.vue'
import userUpdatePassword from '../components/userUpdatePassword.vue'

import mainDashboard from '../components/mainDashboard.vue'
import jury from '../components/jury.vue'
import bewerbungen from '../components/bewerbungen.vue'
import nutzer from '../components/nutzer.vue'
import auswertung from '../components/auswertung.vue'
import statistik from '../components/statistik.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      component: mainDashboard,
      beforeEnter: authGuard,
    },
    {
      path: '/nutzer',
      component: nutzer,
      beforeEnter: authGuard,
    },
    {
      path: '/bewerbungen',
      component: bewerbungen,
      beforeEnter: authGuard,
    },
    {
      path: '/jury',
      component: jury,
      beforeEnter: authGuard,
    },
    {
      path: '/auswertung',
      component: auswertung,
      beforeEnter: authGuard,
    },
    {
      path: '/statistik',
      component: statistik,
      beforeEnter: authGuard,
    },
    {
      path: '/registrieren',
      component: userRegistrieren
    },
    {
      path: '/verifizieremail',
      component: userVerifiziereMail
    },
    {
      path: '/passwortvergessen',
      component: userUpdatePassword
    },
    {
      path: '/anmelden',
      component: userAnmelden
    }
  ],
  mode: 'history'
})