<template>
  <!-- eslint-disable -->
  <v-dialog
    v-model="visible"
    width="80%"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    persistent
  >
    <v-card v-if="bewerbungData.data !== {}">
      <v-card-title :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1' : ''">
        {{ $t('dialogBewerbung.titel') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="showClose = true"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0 pa-md-4 overflow-y">
        <v-row class="pt-6">
          <v-col cols="7" offset="4">
            
          </v-col>
          <v-col cols="10" offset="1">
            <v-row>
              <!-- <v-col cols="12">
                <div class="pl-4">{{ $t('dialogBewerbung.1.subtitel') }}</div>
              </v-col> -->
              <v-col cols="12" md="8" offset-md="4">
                <!-- <v-select hide-details="auto" dense filled v-model="bewerbungData.data.preis" :items="['German SDG-Award', 'UNIDO Innovation Award​']" :label="$t('dialogBewerbung.1.a')"></v-select> -->
              </v-col>
              <v-col cols="12" md="8" offset-md="4">
                <v-alert color="primary" dark title="123" v-if="$i18n.locale === 'fr'">
                  <div class="text-h6">
                    Attention / Note
                  </div>
                  Nous vous prions de bien vouloir rédiger toutes vos réponses écrites, ainsi que les champs libres suivants, en anglais. Merci beaucoup.
                </v-alert>
                <v-select hide-details="auto" dense filled v-model="bewerbungData.data.kategorie" :items="['Unternehmen | Companies | Entreprise', 'Städte, Gemeinden & Landkreise | Cities, Municipalities & Counties | Villes et municipalités', 'Jugend & Bildung | Youth & Education | Jeunesse & éducation']" :label="$t('dialogBewerbung.1.b')"></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-12" v-if="bewerbungData.data.beitrag">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.2.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.2.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[0]" :label="$t('dialogBewerbung.2.a.1')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[1]" :label="$t('dialogBewerbung.2.a.2')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[2]" :label="$t('dialogBewerbung.2.a.3')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[3]" :label="$t('dialogBewerbung.2.a.4')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[4]" :label="$t('dialogBewerbung.2.a.5')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[5]" :label="$t('dialogBewerbung.2.a.6')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[6]" :label="$t('dialogBewerbung.2.a.7')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[7]" :label="$t('dialogBewerbung.2.a.8')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[8]" :label="$t('dialogBewerbung.2.a.9')"></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[9]" :label="$t('dialogBewerbung.2.a.10')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[10]" :label="$t('dialogBewerbung.2.a.11')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[11]" :label="$t('dialogBewerbung.2.a.12')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[12]" :label="$t('dialogBewerbung.2.a.13')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[13]" :label="$t('dialogBewerbung.2.a.14')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[14]" :label="$t('dialogBewerbung.2.a.15')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[15]" :label="$t('dialogBewerbung.2.a.16')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.beitrag[16]" :label="$t('dialogBewerbung.2.a.17')"></v-checkbox>
              </v-col>
              <v-col cols="12" md="8" offset-md="4" class="mt-12">
                <v-checkbox hide-details v-model="bewerbungData.data.globaleDimension" :label="$t('dialogBewerbung.1.c')"></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.2.b.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textBeitrag" rows="15" :rules="[rules.length2000]" counter="2000"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.3.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.3.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textZielsetzung" rows="9" :rules="[rules.length1000]" counter="1000"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.4.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.4.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textZielerreichung" rows="9" :rules="[rules.length1000]" counter="1000"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.5.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.5.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-select hide-details="auto" dense filled v-model="bewerbungData.data.fortschritt" :items="$t('dialogBewerbung.5.a.items')"></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.6.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.6.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-select hide-details="auto" dense filled v-model="bewerbungData.data.selectMitwirkung" :items="$t('dialogBewerbung.8.a.items')"></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.6.b.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textMitwirkung" rows="15" :rules="[rules.length1000]" counter="1000"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-12" v-if="bewerbungData.data.uebertragbarkeit">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.7.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.7.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-checkbox hide-details v-model="bewerbungData.data.uebertragbarkeit[0]" :label="$t('dialogBewerbung.7.a.items.1')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.uebertragbarkeit[1]" :label="$t('dialogBewerbung.7.a.items.2')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.uebertragbarkeit[2]" :label="$t('dialogBewerbung.7.a.items.3')"></v-checkbox>
                <v-checkbox hide-details v-model="bewerbungData.data.uebertragbarkeit[3]" :label="$t('dialogBewerbung.7.a.items.4')"></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.7.b.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textUebertragbarkeit" rows="15" :rules="[rules.length1000]" counter="1000"></v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.7.c.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textUebertragbarkeitTransfer" rows="15" :rules="[rules.length1000]" counter="1000"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.8.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.8.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-select hide-details="auto" dense filled v-model="bewerbungData.data.kooperation" :items="$t('dialogBewerbung.8.a.items')"></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.8.b.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textKooperation" rows="9" :rules="[rules.length1000]" counter="1000"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.9.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.9.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-select hide-details="auto" dense filled v-model="bewerbungData.data.reichweite" :items="$t('dialogBewerbung.9.a.items')"></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.9.b.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textReichweite" rows="9" :rules="[rules.length1000]" counter="600"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.10.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.10.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textWirkung" rows="9" :rules="[rules.length1000]" counter="600"></v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.10.b.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textWirkungInternational" rows="9" :rules="[rules.length1000]" counter="600"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.11.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.11.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-select hide-details="auto" dense filled v-model="bewerbungData.data.messbarkeit" :items="$t('dialogBewerbung.11.a.items')"></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.11.b.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textMessbarkeit" rows="6" :rules="[rules.length1000]" counter="1000"></v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.11.c.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textMessbarkeitIndikatoren" rows="6" :rules="[rules.length1000]" counter="1000"></v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.11.d.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-select hide-details="auto" dense filled v-model="bewerbungData.data.messbarkeitZertifikate" :items="$t('dialogBewerbung.11.d.items')"></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.11.e.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea hide-details="auto" dense filled v-model="bewerbungData.data.textmessbarkeitZertifikate" rows="6" :rules="[rules.length1000]" counter="1000"></v-textarea>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.12.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.12.a.subtitel') }}</div>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field hide-details="auto" dense filled v-model="bewerbungData.data.kosten" :rules="[rules.geld]" :label="$t('dialogBewerbung.12.a.a')"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">{{ $t('dialogBewerbung.12.b.subtitel') }}</div>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field hide-details="auto" dense filled v-model="bewerbungData.data.kosten_oeffentlich" :label="$t('dialogBewerbung.12.b.a')" :rules="[rules.prozent]"></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field hide-details="auto" dense filled v-model="bewerbungData.data.kosten_privat" :label="$t('dialogBewerbung.12.b.b')" :rules="[rules.prozent]"></v-text-field>
              </v-col>
            </v-row> -->
            <v-row class="mt-12">
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.13.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">
                  {{ $t('dialogBewerbung.13.a.subtitel') }}
                </div>
              </v-col>
              <v-col cols="12" md="8">
                <v-file-input :loading="loading" accept=".pdf" :label="$t('dialogBewerbung.13.a.inputstring')" @change="uploadFile()" chips small-chips clearable multiple outlined dense hide-details v-model="anhangFiles" ref="fileAnhang">
                  <template v-slot:selection="{ text, index }">
                    <v-chip label small>
                      {{ text }}
                      <v-progress-circular rotate="-90" v-if="uploadStatus[index] < 100" :value="uploadStatus[index]" class="ml-2" size="20" width="3"></v-progress-circular>
                      <v-icon v-else small class="ml-2">mdi-check</v-icon>
                    </v-chip>
                  </template>
                </v-file-input>
                <div class="mt-4">
                  {{ $t('dialogBewerbung.13.a.answer1') }} {{ bewerbungData.data.anhang.length }} {{ bewerbungData.data.anhang.length === 1 ? $t('dialogBewerbung.13.a.answer2[0]') : $t('dialogBewerbung.13.a.answer2[1]') }} {{ $t('dialogBewerbung.13.a.answer3') }} 
                  {{ $t('dialogBewerbung.13.a.answer4') }}
                </div>
                <draggable class="" v-model="bewerbungData.data.anhang">
                  <div v-for="(item, index) in bewerbungData.data.anhang" :key="index" class="mt-1">
                    <v-card flat outlined class="py-0">
                      <v-card-title class="subtitle-2 py-0">
                        <v-icon class="mr-4">mdi-drag</v-icon>
                        <v-icon color="green" class="mr-2">mdi-check</v-icon>
                        {{ item.name }}
                        <v-spacer></v-spacer> 
                        <v-btn icon @click="deleteFile(index)"><v-icon>mdi-close</v-icon></v-btn>
                      </v-card-title>
                    </v-card>
                  </div>
                </draggable>
              </v-col>
              <v-col cols="12">
                <div class="text-h5">{{ $t('dialogBewerbung.14.titel') }}</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="pl-4">
                  {{ $t('dialogBewerbung.14.a.subtitel') }}
                </div>
              </v-col>
              <v-col cols="12" md="8">
                <v-file-input :loading="loadingSonstige" :label="$t('dialogBewerbung.14.a.inputstring')" @change="uploadFileSonstige()" chips small-chips clearable multiple outlined dense hide-details v-model="anhangFilesSonstige" ref="fileAnhang">
                  <template v-slot:selection="{ text, index }">
                    <v-chip label small>
                      {{ text }}
                      <v-progress-circular rotate="-90" v-if="uploadStatusSonstige[index] < 100" :value="uploadStatusSonstige[index]" class="ml-2" size="20" width="3"></v-progress-circular>
                      <v-icon v-else small class="ml-2">mdi-check</v-icon>
                    </v-chip>
                  </template>
                </v-file-input>
                <div class="mt-4">
                  {{ $t('dialogBewerbung.14.a.answer1') }} {{ bewerbungData.data.anhang_sonstige?.length }} {{ bewerbungData.data.anhang_sonstige?.length === 1 ? $t('dialogBewerbung.14.a.answer2[0]') : $t('dialogBewerbung.14.a.answer2[1]') }} {{ $t('dialogBewerbung.14.a.answer3') }} 
                  {{ $t('dialogBewerbung.14.a.answer4') }}
                </div>
                <draggable class="" v-model="bewerbungData.data.anhang_sonstige">
                  <div v-for="(item, index) in bewerbungData.data.anhang_sonstige" :key="index" class="mt-1">
                    <v-card flat outlined class="py-0">
                      <v-card-title class="subtitle-2 py-0">
                        <v-icon class="mr-4">mdi-drag</v-icon>
                        <v-icon color="green" class="mr-2">mdi-check</v-icon>
                        {{ item.name }}
                        <v-spacer></v-spacer> 
                        <v-btn icon @click="deleteFileSonstige(index)"><v-icon>mdi-close</v-icon></v-btn>
                      </v-card-title>
                    </v-card>
                  </div>
                </draggable>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="showClose = true">{{ $t('schliessen') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()" :disabled="loading">{{ $t('speichern') }}</v-btn>  
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="showClose"
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="mt-6 text-center">
          {{ $t('dialogKontaktdaten.dialogSchliessenText') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showClose = false">{{ $t('zurueck') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close()">{{ $t('schliessen') }}</v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    props: ['data', 'visible'],
    components: {
      draggable
    },
    data() {
      return {
        showClose: false,
        loading: false,
        loadingSonstige: false,
        anhangFiles: [],
        anhangFilesSonstige: [],
        uploadStatus: [],
        uploadStatusSonstige: [],
        rules: {
          required: value => !!value || 'Pflichtfeld',
          length400: v => !v || v.length <= 400 || 'Der Text ist zu lang.',
          length600: v => !v || v.length <= 600 || 'Der Text ist zu lang.',
          length1000: v => !v || v.length <= 1000 || 'Der Text ist zu lang.',
          length2000: v => !v || v.length <= 2000 || 'Der Text ist zu lang.',
          geld: value => {
            const pattern = /^([0-9]+(?:,[0-9]{1,2})?|[0-9]{1,3}(?:\.[0-9]{3})*(?:,[0-9]{1,2})?)$/
            return pattern.test(value) || 'Falsches Format. Bitte geben Sie nur Ziffern, Tausendertrennzeichen (.) oder Dezimalkomma an.'
          },
          prozent: value => {
            const pattern = /^([1-9]\d?|0|100)$/
            return pattern.test(value) || 'Falsches Format. Bitte geben Sie eine Zahl zwischen 0 und 100 ein.'
          },
        }
      }
    },
    computed: {
      bewerbungData () {
        const bewerbungData = { ...this.data }
        return bewerbungData
      },
      user () {
        return this.$store.state.user.user
      },
      personen () {
        return this.$store.state.personen.personen
      }
    },
    methods: {
      close () {
        this.$emit('close', false)
      },
      uploadFile () {
        const uid = this.$firebase.auth().currentUser.uid
        this.loading = true
        let counter = 0
        this.uploadStatus = Array(this.anhangFiles).fill(0)
        this.anhangFiles.forEach(file => {
          const fileName = file.name
          let name = new Date().getTime() + file.name
          const storageRef = this.$firebase.storage().ref('bewerbungAnhang/' + uid + '/' + name).put(file)
          storageRef.on(`state_changed`, snapshot => {
            this.uploadStatus[this.anhangFiles.indexOf(file)] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          }, error => { this.uploadStatus.error = error.message },
          () => {
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.bewerbungData.data.anhang.push({
                name: fileName,
                filename: fileName,
                path: url
              })
              counter++
              if (counter === this.anhangFiles.length) {
                if (this.uploadStatus.every((status) => status === 100)) {
                  this.loading = false
                }
                this.uploadValue = 0
              }
            })
          })
        })
      },
      uploadFileSonstige () {
        const uid = this.$firebase.auth().currentUser.uid
        this.loadingSonstige = true
        let counter = 0
        this.uploadStatusSonstige = Array(this.anhangFilesSonstige).fill(0)
        this.anhangFilesSonstige.forEach(file => {
          const fileName = file.name
          let name = new Date().getTime() + file.name
          const storageRef = this.$firebase.storage().ref('bewerbungAnhang/' + uid + '/' + name).put(file)
          storageRef.on(`state_changed`, snapshot => {
            this.uploadStatusSonstige[this.anhangFilesSonstige.indexOf(file)] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          }, error => { this.uploadStatusSonstige.error = error.message },
          () => {
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              if (!this.bewerbungData.data.anhang_sonstige) {
                this.bewerbungData.data.anhang_sonstige = []
              }
              this.bewerbungData.data.anhang_sonstige.push({
                name: fileName,
                filename: fileName,
                path: url
              })
              counter++
              if (counter === this.anhangFilesSonstige.length) {
                if (this.uploadStatusSonstige.every((status) => status === 100)) {
                  this.loadingSonstige = false
                }
                this.uploadValue = 0
              }
            })
          })
        })
      },
      deleteFile (index) {
        this.bewerbungData.data.anhang.splice(index, 1)
      },
      deleteFileSonstige (index) {
        this.bewerbungData.data.anhang_sonstige.splice(index, 1)
      },
      save () {
        console.log(this.bewerbungData)
        this.bewerbungData.status = 'Entwurf'
        this.bewerbungData.log.push({
          text: 'update',
          date: new Date(),
          user: this.$firebase.auth().currentUser.uid
        })
        this.bewerbungData.sprache = this.$i18n.locale
        this.$store.dispatch('bewerbungen/updateBewerbungData', this.bewerbungData)
        this.$vars.snackbar = {
          show: true,
          text: 'Die Bewerbungsdaten wurden erfolgreich gespeichert.'
        }
        this.$emit('close', false)
      }
    }
  }
</script>

<style>
</style>
