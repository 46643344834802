import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from './modules/user'
import mails from './modules/mails'
import personen from './modules/personen'
import bewerbungen from './modules/bewerbungen'
import bewertungen from './modules/bewertungen'

export const store = new Vuex.Store({
  modules: {
    mails,
    user,
    personen,
    bewerbungen,
    bewertungen
  },
  state: {
    nachricht: '1234'
  }
})
