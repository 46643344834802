import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  user: null,
  userAccount: null,
  loading: false,
  error: null
}

// getters
const getters = {
  user (state) {
    return state.user
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  signUserUp ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    console.log(payload)
    firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
      .then(
        user => {
          user.sendEmailVerification()
          console.log(user)
          commit('setLoading', false)
          .catch(err => {
            commit('setError', err)
          })
          const newUser = {
            id: user.uid
          }
          commit('setUser', newUser)
          return newUser
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserIn ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      .then(
        user => {
          commit('setLoading', false)
          const newUser = user.user.uid
          commit('setUser', newUser)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  resetPassword ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    console.log(payload.email)
    let code = {
      code: 'reset-success'
    }
    firebase.auth().sendPasswordResetEmail(payload.email)
      .then(
        commit('setError', code)
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  autoSignIn ({commit}, payload) {
    commit('setUser', payload.uid)
  },
  logout ({commit}) {
    firebase.auth().signOut()
    commit('setUser', null)
  },
  clearError ({ commit }) {
    commit('clearError')
  }
}

// mutations
const mutations = {
  setUser (state, payload) {
    state.user = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = {
      code: undefined,
      message: undefined
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
