<template>
  <!-- eslint-disable -->
  <v-dialog
    v-model="visible"
    width="80%"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    persistent
  >
    <v-card v-if="bewertungData.data !== {}">
      <v-card-title>
        {{ $t('dialogBewertung.1') }} {{ person.data.institution }}
        <v-spacer></v-spacer>
        <v-btn icon @click="pdfHerunterladen(bewerbung.pdf_merge)"><v-icon>mdi-printer</v-icon></v-btn>
        <v-btn icon @click="showClose = true"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-12">
          <v-col cols="4" offset="4">
            <div class="center-text">
              <v-btn block class="primary" @click="pdfHerunterladen(bewerbung.pdf_merge)">{{ $t('dialogBewertung.2') }}</v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <div class="text-h5">{{ $t('dialogBewertung.3') }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.4') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.5') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[0]">
              <v-btn>{{ $t('dialogBewertung.keine') }}</v-btn>
              <v-btn>1 - 2 SDGs</v-btn>
              <v-btn>3 - 4 SDGs</v-btn>
              <v-btn>5 - 6 SDGs</v-btn>
              <v-btn>7 - 8 SDGs</v-btn>
              <v-btn>9 - 17 SDGs</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[0] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[0] }}
              </v-avatar>
          </v-col>
          <!-- <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.6') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.7') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[1]">
              <v-btn>{{ $t('dialogBewertung.keine') }}</v-btn>
              <v-btn>1</v-btn>
              <v-btn>2</v-btn>
              <v-btn>3</v-btn>
              <v-btn>4</v-btn>
              <v-btn>5</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[1] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[1] }}
              </v-avatar>
          </v-col> -->
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.8') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.9') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[2]">
              <v-btn>0</v-btn>
              <v-btn>1</v-btn>
              <v-btn>2</v-btn>
              <v-btn>3</v-btn>
              <v-btn>4</v-btn>
              <v-btn>5</v-btn>
              <v-btn>6</v-btn>
              <v-btn>7</v-btn>
              <v-btn>8</v-btn>
              <v-btn>9</v-btn>
              <v-btn>10</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[2] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[2] }}
              </v-avatar>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.10') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[3]">
              <v-btn>{{ $t('dialogBewertung.nein') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.teilweise') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.ja') }}</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[3] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[3] }}
              </v-avatar>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-col cols="12">
            <div class="text-h5">{{ $t('dialogBewertung.11') }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.12') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[4]">
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewerbung.5.a.items[0]') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewerbung.5.a.items[1]') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewerbung.5.a.items[2]') }}</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[4] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[4] }}
              </v-avatar>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-col cols="12">
            <div class="text-h5">{{ $t('dialogBewertung.13') }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.14') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.15') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[5]">
              <v-btn>{{ $t('dialogBewertung.nein') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.teilweise') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.grossteils') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.sehrstark') }}</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[5] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[5] }}
              </v-avatar>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-col cols="12">
            <div class="text-h5">{{ $t('dialogBewertung.16') }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.17') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[6]">
              <v-btn>{{ $t('dialogBewertung.nein') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.teilweise') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.gut') }}</v-btn>
              <v-btn>{{ $t('dialogBewertung.sehrstark') }}</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[6] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[6] }}
              </v-avatar>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.18') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.19') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[7]">
              <v-btn>0</v-btn>
              <v-btn>1</v-btn>
              <v-btn>2</v-btn>
              <v-btn>3</v-btn>
              <v-btn>4</v-btn>
              <v-btn>5</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[7] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[7] }}
              </v-avatar>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-col cols="12">
            <div class="text-h5">{{ $t('dialogBewertung.20') }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.21') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.22') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[8]">
              <v-btn>{{ $t('dialogBewertung.nein') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.angedacht') }}</v-btn>
              <v-btn>{{ $t('dialogBewertung.teilweise') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.intensiv') }}</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[8] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[8] }}
              </v-avatar>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">5.2 Wie ist die Kooperation zu bewerten?</div>
            <!-- <div class="pl-4">{{ $t('dialogBewertung.22') }}</div> -->
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[9]">
              <v-btn>0</v-btn>
              <v-btn>1</v-btn>
              <v-btn>2</v-btn>
              <v-btn>3</v-btn>
              <v-btn>4</v-btn>
              <v-btn>5</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[9] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[9] }}
              </v-avatar>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-col cols="12">
            <div class="text-h5">{{ $t('dialogBewertung.23') }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.24') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.25') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[10]">
              <v-btn>{{ $t('dialogBewertung.keine') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.lokal') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.regional') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.national') }}</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>{{ $t('dialogBewertung.international') }}</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[10] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[10] }}
              </v-avatar>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <v-col cols="12">
            <div class="text-h5">{{ $t('dialogBewertung.26') }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.27') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.28') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[11]">
              <v-btn>{{ $t('dialogBewertung.keineWirkung') }}</v-btn>
              <v-btn>1</v-btn>
              <v-btn>2</v-btn>
              <v-btn>3</v-btn>
              <v-btn>4</v-btn>
              <v-btn>5</v-btn>
              <v-btn>6</v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
            </v-btn-toggle>
            <v-btn-toggle v-model="bewertungData.data.punkte[11]">
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn style="display: none;"></v-btn>
              <v-btn>7</v-btn>
              <v-btn>8</v-btn>
              <v-btn>9</v-btn>
              <v-btn>10</v-btn>
              <v-btn>11</v-btn>
              <v-btn>12</v-btn>
              <v-btn>13</v-btn>
              <v-btn>14</v-btn>
              <v-btn>15</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[11] != null ? 'green' : 'grey lighten-1'">
              {{ bewertungData.data.punkte[11] }}
            </v-avatar>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.29') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.30') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[12]">
              <v-btn>0</v-btn>
              <v-btn>1</v-btn>
              <v-btn>2</v-btn>
              <v-btn>3</v-btn>
              <v-btn>4</v-btn>
              <v-btn>5</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[12] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[12] }}
              </v-avatar>
          </v-col>
          <v-col cols="12" md="4">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.31') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.32') }}</div>
          </v-col>
          <v-col cols="12" md="7" class="justify-right">
            <v-btn-toggle v-model="bewertungData.data.punkte[13]">
              <v-btn>{{ $t('dialogBewertung.nein') }}</v-btn>
              <v-btn>1</v-btn>
              <v-btn>2</v-btn>
              <v-btn>3</v-btn>
              <v-btn>4</v-btn>
              <v-btn>5</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="1" class="justify-center">
            <v-avatar size="32" class="white--text font-weight-bold" :color="bewertungData.data.punkte[13] != null ? 'green' : 'grey lighten-1'">
                {{ bewertungData.data.punkte[13] }}
              </v-avatar>
          </v-col>
        </v-row>
        <!-- <v-row class="mt-12">
          <v-col cols="12">
            <div class="text-h5">{{ $t('dialogBewertung.38') }}</div>
          </v-col>
          <v-col cols="12" md="8">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.39') }}</div>
            <div class="pl-4">{{ $t('dialogBewertung.40') }}</div>
          </v-col>
          <v-col cols="12" md="4" class="justify-right">
            <v-switch v-model="bewertungData.data.unidopreis">
            </v-switch>
          </v-col>
        </v-row> 
        <v-row>
          <v-col cols="12">
            <v-textarea hide-details="auto" dense filled :label="$t('dialogBewertung.35')" v-model="bewertungData.data.unidoanmerkung" rows="4"></v-textarea>
          </v-col>
        </v-row>  -->      
        <v-row class="mt-12">
          <v-col cols="12">
            <div class="text-h5">{{ $t('dialogBewertung.33') }}</div>
          </v-col>
          <v-col cols="12" md="8">
            <div class="pl-4 font-weight-bold">{{ $t('dialogBewertung.34') }}</div>
          </v-col>
          <v-col cols="12" md="4" class="justify-right">
            <v-switch v-model="bewertungData.data.sonderpreis">
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea hide-details="auto" dense filled :label="$t('dialogBewertung.35')" v-model="bewertungData.data.anmerkung" rows="4"></v-textarea>
          </v-col>
        </v-row>        
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="showClose = true">{{ $t('schliessen') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn text>
          {{ $t('dialogBewertung.36') }}
          {{ bewertungData.data.punkte.reduce((sum, current) => sum + current, 0) }}
          {{ $t('dialogBewertung.37') }}
        </v-btn>
        <v-btn color="primary" @click="save()" :disabled="!bewertungData.data.punkte.reduce((sum, current) => sum + current, 0) > 0">{{ $t('speichern') }}</v-btn>  
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="showClose"
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="mt-6 text-center">
          {{ $t('dialogKontaktdaten.dialogSchliessenText') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showClose = false">{{ $t('zurueck') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close()">{{ $t('schliessen') }}</v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
  export default {
    props: ['data', 'visible'],
    data() {
      return {
        showClose: false,
        loading: false,
        anhangFiles: [],
        uploadStatus: [],
        rules: {
          required: value => !!value || 'Pflichtfeld',
          length400: v => !v || v.length <= 400 || 'Der Text ist zu lang.',
          length600: v => !v || v.length <= 600 || 'Der Text ist zu lang.',
          length1000: v => !v || v.length <= 1000 || 'Der Text ist zu lang.',
          length2000: v => !v || v.length <= 2000 || 'Der Text ist zu lang.',
          geld: value => {
            const pattern = /^([0-9]+(?:,[0-9]{1,2})?|[0-9]{1,3}(?:\.[0-9]{3})*(?:,[0-9]{1,2})?)$/
            return pattern.test(value) || 'Falsches Format. Bitte geben Sie nur Ziffern, Tausendertrennzeichen (.) oder Dezimalkomma an.'
          },
          prozent: value => {
            const pattern = /^([1-9]\d?|0|100)$/
            return pattern.test(value) || 'Falsches Format. Bitte geben Sie eine Zahl zwischen 0 und 100 ein.'
          },
        }
      }
    },
    computed: {
      bewertungData () {
        const bewertungData = { ...this.data }
        return bewertungData
      },
      user () {
        return this.$store.state.user.user
      },
      personen () {
        return this.$store.state.personen.personen
      },
      person () {
        return this.$store.state.personen.personen.find(item => item.anmeldeID === this.bewerbung.anmeldeID)
      },
      bewerbung () {
        return this.$store.state.bewerbungen.bewerbungen.find(item => item.id === this.bewertungData.bewerbungID)
      }
    },
    methods: {
      changePunkte (item, value) {
        this.bewertungData.data.punkte[item] = value
      },
      pdfHerunterladen (ref) {
        this.loading = true
        return this.$firebase.storage().ref(ref).getDownloadURL()
          .then(url => {
            const link = document.createElement('a')
            link.href = url
            link.target = '_blank'
            link.download = ' Bewerbungsunterlagen_' + this.person.data.institution.replace('.', '') + '.pdf'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.loading = false
          })
          .catch((error) => {
            this.error = '#mD400: ' + error
            this.showError = true
            this.loading = false
            throw error;
          });
      },
      close () {
        this.$emit('close', false)
      },
      save () {
        let count = this.bewertungData.data.punkte.filter(punkt => punkt !== null && !isNaN(punkt))
        this.bewertungData.fortschritt = count.length
        this.bewertungData.log.push({
          text: 'update',
          date: new Date(),
          user: this.$firebase.auth().currentUser.uid
        })
        delete this.bewertungData.bewerbungData
        console.log(this.bewertungData)
        this.$store.dispatch('bewertungen/updateBewertungData', this.bewertungData)
        this.$vars.snackbar = {
          show: true,
          text: 'Die Bewertungsdaten wurden erfolgreich gespeichert.'
        }
        this.$emit('close', false)
      }
    }
  }
</script>

<style>
</style>
