<template>
  <v-container fluid>
    <v-row class="pa-2">
      <v-col cols="12">
        <v-toolbar dense flat rounded>
          <v-toolbar-title>Nutzerübersicht ({{ personen.length }})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-text-field
              v-model='search'
              append-icon='mdi-magnify'
              label='Suchen'
              single-line
              solo
              flat
              hide-details
              clearable
              clear-icon="mdi-close"
            ></v-text-field>
            <v-divider vertical class="px-1"></v-divider>
            <v-btn icon><v-icon @click="exportData()">mdi-download</v-icon></v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-col>
      <v-col cols="12">
        <v-card class="mb-2 pt-1">
          <v-data-table
            dense
            :height="'calc(100vh - 352px)'"
            :headers="headers"
            :items="personen"
            :search="search"
            fixed-header
            hide-default-footer
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            :items-per-page="-1"
            no-data-text="Keine Nutzer vorhanden"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-progress-circular  :value="personDataComplete (item) * 100" :rotate="-90" color="primary" size="30" class="mr-2 ml-n1">
                    <v-icon v-if="personDataComplete (item) >= 1" color="green" class="pr-0">mdi-check-circle</v-icon>
                  </v-progress-circular>
                  
                </td>
                <td>{{ item.data.nachname }}</td>
                <td>{{ item.data.vorname }}</td>
                <td>{{ item.data.mail }}</td>
                <td>{{ item.rolle }}</td>
                <td class="px-0" width="20px"><v-btn icon @click="(personData = item) && (showNutzerdaten = true)"><v-icon>mdi-pencil</v-icon></v-btn></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="personData">
      <dialogNutzerdaten :visible.sync="showNutzerdaten" :data="personData" @close="showNutzerdaten = false"></dialogNutzerdaten>
    </div>
  </v-container>
</template>

<script>
  import papa from 'papaparse'
  import dialogNutzerdaten from './dialogKontaktdaten'
  export default {
    components: {
      dialogNutzerdaten
    },
    name: 'mainDashboard',
    data() {
      return {
        showNutzerdaten: false,
        personData: {
          data: {}
        },
        loading: false,
        search: '',
        headers: [
          { number: '0', text: '', align: 'left', value: '' },
          { number: '1', text: 'Nachname', align: 'left', value: 'data.nachname' },
          { number: '2', text: 'Vorname', align: 'left', value: 'data.vorname' },
          { number: '3', text: 'Mail', align: 'left', value: 'data.mail' },
          { number: '4', text: 'Rolle', align: 'left', value: 'rolle' },
          { number: '5', text: '', align: 'left', value: '' }
        ]
      }
    },
    computed: {
      user () {
        return this.$store.state.user.user
      },
      personen () {
        return this.$store.state.personen.personen
      }
    },
    methods: {
      personDataComplete (data) {
        let count = 0
        let gesamt = 14
        if (data) {
          for (let prop in data.data) {
            if ([
              'institution',
              'adresse',
              'plz',
              'ort',
              'land',
              'ceoanrede',
              'ceovorname',
              'ceonachname',
              'ceomail',
              'anrede',
              'mail',
              'nachname',
              'vorname',
              'telefonnummer'
            ].includes(prop) && data.data[prop]) {
              count++
            }
          }
        }
        return (count / gesamt) ? (count / gesamt) : 0
      },
      exportData () {
        let data = []
        this.personen.forEach(item => {
          data.push({
            anmeldeID: item.anmeldeID,
            institution: item.data.institution,
            webseite: item.data.webseite,
            adresse: item.data.adresse,
            adresszusatz: item.data.adresszusatz,
            plz: item.data.plz,
            ort: item.data.ort,
            land: item.data.land,
            ansprechpartner_anrede: item.data.anrede,
            ansprechpartner_titel: item.data.titel,
            ansprechpartner_vorname: item.data.vorname,
            ansprechpartner_nachname: item.data.nachname,
            ansprechpartner_telefonnummer: item.data.telefonnummer,
            ansprechpartner_mail: item.data.mail,
            vorstand_anrede: item.data.ceoanrede,
            vorstand_titel: item.data.ceotitel,
            vorstand_vorname: item.data.ceovorname,
            vorstand_nachname: item.data.ceonachname,
            vorstand_telefonnummer: item.data.ceotelefonnummer,
            vorstand_mail: item.data.ceomail,
            erstellt: new Date(item.erstellt.seconds * 1000).toLocaleString('de-DE'),
            rolle: item.rolle
          })
        })
        var csv = papa.unparse(data, {'header': true, encoding: 'UTF-8'})
        const anchor = document.createElement('a')
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        anchor.target = '_blank'
        anchor.download = 'Export-Nutzer.csv'
        anchor.click()
      },
      saveChat () {
        const chat = {
          cards: this.cards,
          message: this.message,
          user: this.$firebase.auth().currentUser.uid,
          time: new Date()
        }
        this.$store.dispatch('chats/createChat', chat)
      }
    }
  }
</script>

<style>
</style>
