<template>
  <!-- eslint-disable -->
  <v-dialog
    v-model="visible"
    width="60%"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('dialogKontaktdaten.titel') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="showClose = true"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-md-12 pa-6">
        <div class="text-h5">{{ $t('dialogKontaktdaten.1.titel') }}</div>
        <div class="pt-1 pb-4">{{ $t('dialogKontaktdaten.1.subtitel') }}</div>
        <v-row class="px-4 pb-16">
          <v-col cols="12">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.institution" :label="$t('dialogKontaktdaten.1.a')" :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.webseite" :label="$t('dialogKontaktdaten.1.b')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.adresse" :label="$t('dialogKontaktdaten.1.c')" :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.adresszusatz" :label="$t('dialogKontaktdaten.1.d')"></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.plz" :label="$t('dialogKontaktdaten.1.e')" :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.ort" :label="$t('dialogKontaktdaten.1.f')" :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col cols="6" sm="4">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.land" :label="$t('dialogKontaktdaten.1.g')" :rules="[rules.required]"></v-text-field>
          </v-col>
        </v-row>
        <div class="text-h5 d-flex justify-space-between">
          {{ $t('dialogKontaktdaten.3.titel') }}
        </div>
        <div class="pt-1 pb-4">{{ $t('dialogKontaktdaten.3.subtitel') }}</div>
        <v-row class="px-4 pb-16">
          <v-col cols="6" md="4">
            <v-select hide-details="auto" dense filled v-model="personData.data.anrede" :items="$t('dialogKontaktdaten.3.aItems')" :label="$t('dialogKontaktdaten.3.a')" :rules="[rules.required]"></v-select>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.titel" :label="$t('dialogKontaktdaten.3.b')"></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-select hide-details="auto" dense filled v-model="personData.data.language" :items="$t('dialogKontaktdaten.3.gItems')" :label="$t('dialogKontaktdaten.3.g')" :rules="[rules.required]"></v-select>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.vorname" :label="$t('dialogKontaktdaten.3.c')" :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.nachname" :label="$t('dialogKontaktdaten.3.d')" :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.telefonnummer" :label="$t('dialogKontaktdaten.3.e')" :rules="[rules.required, rules.telefonnummer]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.mail" :label="$t('dialogKontaktdaten.3.f')" :rules="[rules.required, rules.email]"></v-text-field>
          </v-col>
        </v-row>
        <div class="text-h5 d-flex justify-space-between">
          {{ $t('dialogKontaktdaten.2.titel') }}
          <v-btn class="mr-4" @click="vonOben()" small outlined>{{ $t('dialogKontaktdaten.2.take') }}</v-btn>
        </div>
        <div class="pt-1 pb-4">{{ $t('dialogKontaktdaten.2.subtitel') }}</div>
        <v-row class="px-4 pb-16">
          <v-col cols="6" md="4">
            <v-select hide-details="auto" dense filled v-model="personData.data.ceoanrede" :items="$t('dialogKontaktdaten.2.aItems')" :label="$t('dialogKontaktdaten.2.a')" :rules="[rules.required]"></v-select>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.ceotitel" :label="$t('dialogKontaktdaten.2.b')"></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.ceovorname" :label="$t('dialogKontaktdaten.2.c')" :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.ceonachname" :label="$t('dialogKontaktdaten.2.d')" :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.ceotelefonnummer" :label="$t('dialogKontaktdaten.2.e')" :rules="[rules.telefonnummer]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field hide-details="auto" dense filled v-model="personData.data.ceomail" :label="$t('dialogKontaktdaten.2.f')" :rules="[rules.required, rules.email]"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="personData.rolle === 'Mitarbeiter' | personData.rolle === 'Administrator'" class="green lighten-4">
          <v-col cols="12">
            <v-chip label class="green">Dieser Bereich ist nur für Mitarbeiter sichtbar.</v-chip>
          </v-col>
          
          <v-col cols="6">
            <v-select hide-details="auto" dense filled v-model="personData.rolle" :items="['Bewerber', 'Jury', 'Mitarbeiter', 'Administrator']" label="Rolle"></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea hide-details="auto" dense filled v-model="personData.bemerkung" label="Bemerkung"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="showClose = true">{{ $t('abbrechen') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="primary" @click="save()">{{ $t('speichern') }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="showClose"
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="mt-6 text-center">
          {{ $t('dialogKontaktdaten.dialogSchliessenText') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showClose = false">{{ $t('zurueck') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close()">{{ $t('schliessen') }}</v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
  export default {
    props: ['data', 'visible'],
    data() {
      return {
        loading: false,
        showClose: false,
        rules: {
          required: value => !!value || 'Pflichtfeld | Mandatory field',
          email: value => {
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return pattern.test(value) || 'Falsches E-Mail-Format | Incorrect email format'
          },
          telefonnummer: value => {
            const pattern = /^(00|\+|0)[1-9][0-9]*([ ][0-9]+)*$/
            return pattern.test(value) || 'Falsches Telefonnummern-Format. Die Telefonnummer muss entweder mit der Ländervorwahl (+49) oder einer 0 beginnen. | Incorrect phone number format. The phone number must begin with either the country code (+49) or a 0.'
          }
        }
      }
    },
    computed: {
      personData () {
        const personData = { ...this.data }
        if (personData.language === undefined) {
          console.log(undefined)
        }
        return personData
      },
      user () {
        return this.$store.state.user.user
      },
      personen () {
        return this.$store.state.personen.personen
      }
    },
    methods: {
      close () {
        this.$emit('close', false)
      },
      vonOben () {
        this.personData.data.ceoanrede = this.personData.data.anrede ? this.personData.data.anrede : ''
        this.personData.data.ceotitel = this.personData.data.titel ? this.personData.data.titel : ''
        this.personData.data.ceovorname = this.personData.data.vorname ? this.personData.data.vorname : ''
        this.personData.data.ceonachname = this.personData.data.nachname ? this.personData.data.nachname : ''
        this.personData.data.ceotelefonnummer = this.personData.data.telefonnummer ? this.personData.data.telefonnummer : ''
        this.personData.data.ceomail = this.personData.data.mail ? this.personData.data.mail : ''
      },
      save () {
        this.personData.sprache = this.$i18n.locale
        this.$store.dispatch('personen/updatePersonData', this.personData)
        this.$emit('close', false)
      }
    }
  }
</script>

<style>
</style>
