<template>
  <v-container fluid>
    <v-row class="pa-2">
      <v-col cols="12" class="text-center white--text" :class="$vuetify.breakpoint.mdAndUp ? 'mt-6' : 'mt-0'">
        <div class="font-weight-bold hidden-sm-and-down" :class="$vuetify.breakpoint.mdAndUp ? 'text-h3' : 'text-h5'">
          SDG Innovation Award 2024
        </div>
        <div class="text-h4 pb-6 pb-md-12 hidden-sm-and-down" :class="$vuetify.breakpoint.mdAndUp ? 'text-h4' : 'text-h6'">
          {{ $t('bewerbungsportal') }}
        </div>
        <v-alert class="hidden-md-and-up white--text" border="left" text outlined dismissible>
          {{ $t('mainDashboard.empfehlungpc') }}
        </v-alert>
        {{ $t('mainDashboard.willkommen1') }} <span v-if="personData">{{ personData.data.anrede }} {{ personData.data.nachname }},</span><br> {{ $t('mainDashboard.willkommen2') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="bewerbungData ? bewerbungData.status === 'Abgesendet' : false">
        <v-card>
          <v-card-text class="text-center py-6">
            <v-icon color="green" size="96" class="pr-2 pb-8">mdi-check-circle</v-icon>
            <br>{{ $t('mainDashboard.bewerbungeingereicht1') }}
            <br>{{ $t('mainDashboard.bewerbungeingereicht2') }}
            <br><v-btn :loading="loading" outlined class="mt-6 mb-4" @click="pdfHerunterladen(bewerbungData.pdf_merge)"><v-icon>mdi-download</v-icon>{{ $t('herunterladen') }}</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-else cols="12" class="overflow-y">


        <!-- Status User Mailadresse Verifiziert -->
        <!-- <v-card v-if="!userMailVerified" class="mb-2">
          <v-card-title>
            <v-icon color="red" class="pr-2">mdi-close-circle</v-icon>
            Mailadresse nicht verifiziert
          </v-card-title>
          <v-card-text>
            Um den Bewerbungsprozess abzuschließen muss Ihre Mailadresse verifiziert werden. Eine Mail zur Verifikation wurde bei der Registrierung an Ihre Mailadresse versendet. Sollte dies nicht passiert seien können Sie hier erneut eine Mail zusenden. Schauen Sie auch in Ihrem Spam-Ordner nach.
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn class="primary" @click="sendVerifizierungsMail()">Verifizierungsmail senden</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="userMailVerified" class="mb-2">
          <v-card-title>
            <v-icon color="green" class="pr-2">mdi-check-circle</v-icon>
            Mailadresse verifiziert</v-card-title>
        </v-card> -->


        <!-- Status User Nutzerdaten -->
        <v-card v-if="personData" class="mb-2">
          <span v-if="personDataComplete < 1">
            <v-card-title>
              <v-progress-circular :value="personDataComplete * 100" :rotate="-90" color="primary" size="30" class="mr-2 ml-n1">
              </v-progress-circular>
              {{ $t('mainDashboard.kontaktdatenUnvollstaendig') }}
              <v-spacer></v-spacer>
              <v-btn class="primary" text @click="showKontaktdaten()">{{ $t('mainDashboard.kontaktdatenBearbeitenBtn') }}</v-btn>
            </v-card-title>
            <v-card-text>
              {{ $t('mainDashboard.kontaktdatenUnvollstaendigText') }}
            </v-card-text>
          </span>
          <span v-else>
            <v-card-title>
              <v-icon color="green" class="pr-2">mdi-check-circle</v-icon>
              {{ $t('mainDashboard.kontaktdatenVollstaendig') }}
              <v-spacer></v-spacer>
              <v-btn :block="$vuetify.breakpoint.xsOnly" elevation="0" @click="showKontaktdaten()">{{ $t('mainDashboard.kontaktdatenBearbeitenBtn') }}</v-btn>
            </v-card-title>
          </span>
        </v-card>
        <v-card v-else class="mb-2">
          <v-card-title>
            <v-icon color="grey" class="pr-2">mdi-circle</v-icon>
            {{ $t('mainDashboard.kontaktdatenUnvollstaendig') }}
            <v-spacer></v-spacer>
            <v-btn :block="$vuetify.breakpoint.xsOnly" class="primary" text @click="createPersonData()">{{ $t('mainDashboard.kontaktdatenErstellenBtn') }}</v-btn>
          </v-card-title>
        </v-card>


        <!-- Status Bewerbungsdaten -->
        <v-card v-if="bewerbungData" class="mb-2">
          <span v-if="bewerbungDataComplete < 1">
            <v-card-title >
              <v-progress-circular :value="bewerbungDataComplete * 100" :rotate="-90" color="primary" size="30" class="mr-2 ml-n1"></v-progress-circular>
              {{ $t('mainDashboard.bewerbungsunterlagenUnvollstaendig') }}
              <v-spacer></v-spacer>
              <v-btn :block="$vuetify.breakpoint.xsOnly" class="primary" text @click="showBewerbungdaten()">{{ $t('mainDashboard.bewerbungsunterlagenBearbeitenBtn') }}</v-btn>
            </v-card-title>
            <v-card-text>
              {{ $t('mainDashboard.bewerbungsunterlagenUnvollstaendigText') }}
            </v-card-text>
          </span>
          <span v-else>
            <v-card-title>
              <v-icon color="green" class="pr-2">mdi-check-circle</v-icon>
              {{ $t('mainDashboard.bewerbungsunterlagenVollstaendig') }}
              <v-spacer></v-spacer>
              <v-btn :block="$vuetify.breakpoint.xsOnly" elevation="0" @click="showBewerbungdaten()">{{ $t('mainDashboard.bewerbungsunterlagenBearbeitenBtn') }}</v-btn>
            </v-card-title>
          </span>
        </v-card>
        <v-card v-else class="mb-2">
          <v-card-title>
            <v-icon color="grey" class="pr-2">mdi-circle</v-icon>
            {{ $t('mainDashboard.bewerbungsunterlagenUnvollstaendig') }}
            <v-spacer></v-spacer>
            <v-btn :block="$vuetify.breakpoint.xsOnly" class="primary" text @click="createBewerbungData()">{{ $t('mainDashboard.bewerbungsunterlagenErstellenBtn') }}</v-btn>
          </v-card-title>
        </v-card>

        <!-- Bewerbung absenden -->
        <v-card v-if="bewerbungComplete" class="mt-8">
          <v-card-title>
            <v-icon color="green" class="pr-2">mdi-check-circle</v-icon>
            {{ $t('mainDashboard.bewerbungVollstaendig') }}
          </v-card-title>
          <v-card-text>
            {{ $t('mainDashboard.bewerbungVollstaendigText') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :block="$vuetify.breakpoint.xsOnly"  class="mb-2" text @click="printBewerbung()">{{ $t('vorschau') }}</v-btn>
          </v-card-actions>
          <v-card-text>
            <v-checkbox hide-details class="ml-8" v-model="bewerbungData.datenverarbeitung" :label="$t('mainDashboard.datenverarbeitung')"></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :block="$vuetify.breakpoint.xsOnly" :disabled="!bewerbungData.datenverarbeitung" class="primary mb-2" @click="showAbsenden = true">{{ $t('mainDashboard.bewerbungAbsendenBtn') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="personData">
      <dialogNutzerdaten :visible.sync="showNutzerdaten" :data="personData" @close="showNutzerdaten = false"></dialogNutzerdaten>
    </div>
    <div v-if="bewerbungData">
      <dialogBewerbung :visible.sync="showBewerbung" :data="bewerbungData" @close="showBewerbung = false"></dialogBewerbung>
    </div>

    <v-dialog
      v-model="showAbsenden"
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="mt-6 text-center">
          {{ $t('mainDashboard.dialogAbsendenText') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showClose = false">{{ $t('zurueck') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="absenden()" :loading="loading">{{ $t('mainDashboard.dialogAbsendenBtn') }}</v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showError"
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'"
      scrollable
      persistent
    >
      <v-card>
        <v-system-bar class="warning" height="12"></v-system-bar>
        <v-card-text class="mt-6 text-center">
          {{ $t('mainDashboard.dialogerrorText') }} <br><a href="mailto:portal@german-sdg-award.de">portal@german-sdg-award.de</a>
          <br><br>
          {{ error }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="showError = false">{{ $t('schliessen') }}</v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import dialogNutzerdaten from './dialogKontaktdaten'
  import dialogBewerbung from './dialogBewerbung'
  import { printBewerbung } from '../functions/printBewerbung.js'
  export default {
    components: {
      dialogNutzerdaten,
      dialogBewerbung
    },
    name: 'mainDashboard',
    data() {
      return {
        loading: false,
        showNutzerdaten: false,
        showBewerbung: false,
        showAbsenden: false,
        showError: false,
        error: null
      }
    },
    computed: {
      user () {
        return this.$store.state.user.user
      },
      userMailVerified () {
        return this.$firebase.auth().currentUser.emailVerified
      },
      personData () {
        if (!this.$store.state.personen.loaded) {
          this.$store.dispatch('personen/loadPersonen')
        }
        return this.$store.getters['personen/personUID'](this.$store.state.user.user)
      },
      bewerbungData () {
        if (!this.$store.state.bewerbungen.loaded) {
          this.$store.dispatch('bewerbungen/loadBewerbungen')
        }
        return this.$store.getters['bewerbungen/bewerbungUID'](this.$store.state.user.user)
      },
      personDataComplete () {
        let personData = { data: {} }
        if (this.$store.state.personen.loaded) {
          personData = this.personData
        }
        let count = 0
        let gesamt = 15
        if (personData) {
          for (let prop in personData.data) {
            if ([
              'institution',
              'adresse',
              'plz',
              'ort',
              'land',
              'ceoanrede',
              'ceovorname',
              'ceonachname',
              'ceomail',
              'anrede',
              'mail',
              'nachname',
              'vorname',
              'telefonnummer',
              'language'
            ].includes(prop) && personData.data[prop]) {
              count++
            }
          }
        }
        return (count / gesamt) ? (count / gesamt) : 0
      },
      bewerbungDataComplete () {
        let bewerbungData = { data: {} }
        if (this.$store.state.bewerbungen.loaded) {
          bewerbungData = this.bewerbungData
        }
        console.log(bewerbungData)
        let count = 0
        let gesamt = 22
        if (bewerbungData) {
          for (let prop in bewerbungData.data) {
            if ((prop !== 'anhang') && (prop !== 'anhang_sonstige') && prop !== 'globaleDimension') {
              if (bewerbungData.data[prop]) {
                count++
              }
            }
          }
        }
        return (count / gesamt) ? (count / gesamt) : 0
      },
      bewerbungComplete () {
        return (this.personDataComplete === 1 && this.bewerbungDataComplete >= 1)
      },
    },
    methods: {
      sendVerifizierungsMail () {
        let user = this.$firebase.auth().currentUser
        if (!this.$firebase.auth().currentUser.emailVerified) {
          user.sendEmailVerification().then(function () {
            console.log('Verification Mail send')
            this.$vars.snackbar = {
              show: true,
              text: 'Mail zur Verifikation wurde gesendet. Bitte schauen Sie auch in Ihrem Spam-Ordner nach.'
            }            
          }).catch(error => {
            console.log(error)
          })
        }
        return user
      },
      createPersonData () {
        const personData = {
          anmeldeID: this.$firebase.auth().currentUser.uid,
          erstellt: new Date(),
          rolle: 'Bewerber',
          data: {},
          sprache: this.$i18n.locale
        }
        this.$store.dispatch('personen/createPerson', personData)
        this.showKontaktdaten()
      },
      createBewerbungData () {
        const bewerbungData = {
          anmeldeID: this.$firebase.auth().currentUser.uid,
          erstellt: new Date(),
          log: [{
            text: 'erstellt',
            date: new Date(),
            user: this.$firebase.auth().currentUser.uid
          }],
          status: 'Entwurf',
          data: {
            beitrag: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            uebertragbarkeit: [false, false, false, false],
            preis: '',
            anhang: [],
            anhang_sonstige: []
          }
        }
        this.$store.dispatch('bewerbungen/createBewerbung', bewerbungData)
        this.showBewerbungdaten()
      },
      showKontaktdaten () {
        // this.personData = this.personData
        this.showNutzerdaten = true
      },
      showBewerbungdaten () {
        // this.bewerbungData = this.bewerbungData
        this.showBewerbung = true
      },
      printBewerbung () {
        const bewerbung = this.bewerbungData
        const person = this.personData.data
        const vorschau = true
        printBewerbung(bewerbung, person, vorschau)
      },
      async absenden () {
        this.loading = true
        let bewerbungsdaten = this.bewerbungData
        const person = this.personData.data
        try {
          const answer = await printBewerbung(bewerbungsdaten, person, false)
          bewerbungsdaten.pdf = answer;
        } catch (error) {
          this.error = '#mD346: ' + error;
          this.showError = true;
          bewerbungsdaten.log.push({
            text: 'error',
            date: new Date(),
            user: this.$firebase.auth().currentUser.uid
          });
          return;
        }
        try {
          const answer = await this.mergePDF(bewerbungsdaten, person)
          bewerbungsdaten.pdf_merge = answer;
          bewerbungsdaten.log.push({
            text: 'absenden',
            date: new Date(),
            user: this.$firebase.auth().currentUser.uid
          });
          bewerbungsdaten.status = 'Abgesendet'
          bewerbungsdaten.abgesendet = new Date()
        } catch (error) {
          this.error = '#mD359: ' + error;
          this.showError = true;
          bewerbungsdaten.log.push({
            text: 'error',
            date: new Date(),
            user: this.$firebase.auth().currentUser.uid
          });
        }
        bewerbungsdaten.nummer = 0
        this.$store.state.bewerbungen.bewerbungen.forEach(item => {
          if (item.nummer > bewerbungsdaten.nummer) {
            bewerbungsdaten.nummer = item.nummer
          }
        })
        bewerbungsdaten.nummer++
        this.$store.dispatch('bewerbungen/updateBewerbungData', bewerbungsdaten)
        // this.$firebase.storage().ref(bewerbungsdaten.pdf).getDownloadURL()
        //   .then(() => {
            
        //   })
        //   .catch((error) => {
        //     this.error = '#mD385: ' + error
        //     this.showError = true
        //     this.showAbsenden = false
        //     this.loading = false
        //     throw new Error(error)
        //   });
        this.$store.dispatch('mails/createMail', {
          to: [person.mail],
          bcc: 'portal@sdg-innovation.com',
          message: {
            subject: 'Bewerbungsunterlagen erfolgreich eingereicht.',
            text: `
              Sehr geehrte Damen und Herren,

              vielen Dank für Ihre Bewerbung um den SDG Innovation Award 2024.
              Ihre Bewerbungsunterlagen wurden erfolgreich eingereicht.
              Bei Rückfragen wenden Sie sich gerne an sdg-award@senat-deutschland.de.

              Wir werden Sie gern über den Fortgang Ihrer Bewerbung informieren.

              Herzliche Grüße
              ------
              Dear Sir or Madam,

              Thank you for your application for the SDG Innovation Award 2024.
              Your application documents have been successfully submitted.
              If you have any questions, please contact sdg-award@senat-deutschland.de.

              We will keep you informed about the progress of your application.

              Kind regards
              ------
              Chère Madame, cher Monsieur,

              nous vous remercions pour votre candidature au SDG Innovation Award 2024.
              Vos documents de candidature ont été soumis avec succès.
              Si vous avez des questions, n'hésitez pas à nous contacter à l'adresse sdg-award@senat-deutschland.de.

              Nous vous tiendrons informés de l'avancement de votre candidature.

              Cordialement
                  `,
                  html: `
              Sehr geehrte Damen und Herren,<br><br>
              vielen Dank für Ihre Bewerbung um den SDG Innovation Award 2024.<br>
              Ihre Bewerbungsunterlagen wurden erfolgreich eingereicht.<br>
              Bei Rückfragen wenden Sie sich gerne an <a href="mailto:sdg-award@senat-deutschland.de">sdg-award@senat-deutschland.de</a>.<br><br>
              Wir werden Sie gern über den Fortgang Ihrer Bewerbung informieren.<br><br>
              Herzliche Grüße<br><br><br>------------------<br><br>
              Dear Sir or Madam,<br><br>
              Thank you for your application for the SDG Innovation Award 2024.<br>
              Your application documents have been successfully submitted.<br>
              If you have any questions, please contact <a href="mailto:sdg-award@senat-deutschland.de">sdg-award@senat-deutschland.de</a>.<br><br>
              We will keep you informed about the progress of your application.<br><br>
              Kind regards<br><br><br>------------------<br><br>
              Chère Madame, cher Monsieur,<br><br>
              nous vous remercions pour votre candidature au SDG Innovation Award 2024.<br>
              Vos documents de candidature ont été soumis avec succès.<br>
              Si vous avez des questions, n'hésitez pas à nous contacter à l'adresse <a href="mailto:sdg-award@senat-deutschland.de">sdg-award@senat-deutschland.de</a>.<br><br>
              Nous vous tiendrons informés de l'avancement de votre candidature.<br><br>
              Cordialement
                  `,
              }
        })
        this.$vars.snackbar.text = "Bewerbung wurde eingereicht."
        this.$vars.snackbar.show = true
        this.showAbsenden = false
        this.loading = false
      },
      async mergePDF (bewerbungsdaten, person) {
        let pdfurls = []
        const url = await this.$firebase.storage().ref(bewerbungsdaten.pdf).getDownloadURL()
          .then((url) => {
            return url
          })
          .catch((error) => {
            this.error = '#mD385: ' + error
            this.showError = true
            this.showAbsenden = false
            this.loading = false
            throw new Error(error)
          });
        pdfurls.push(url)
        bewerbungsdaten.data.anhang.forEach(item => {
          pdfurls.push(item.path)
        })
        const name = person.institution.replace('.', '') + '_' + bewerbungsdaten.anmeldeID + '_merged.pdf'
        return this.$firebase.functions().httpsCallable('mergePDFs')({ urls: pdfurls, name: name })
          .then(() => {
            return this.$firebase.storage().ref('bewerbungPDFmerged/' + name).getDownloadURL()
              .then(() => {
                return 'bewerbungPDFmerged/' + name
              })
              .catch((error) => {
                this.error = '#mD393: ' + error
                this.showError = true
                this.showAbsenden = false
                this.loading = false
                throw new Error(error)
              });
          })
          .catch(error => {
            this.error = '#mD401: ' + error
            this.showError = true
            this.showAbsenden = false
            this.loading = false
            throw new Error(error)
          })
      },
      pdfHerunterladen (ref) {
        this.loading = true
        return this.$firebase.storage().ref(ref).getDownloadURL()
          .then(url => {
            const link = document.createElement('a')
            link.href = url
            link.target = '_blank'
            link.download = this.bewerbungData.data.preis + ' Bewerbungsunterlagen_' + this.personData.data.institution.replace('.', '') + '.pdf'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.loading = false
          })
          .catch((error) => {
            this.error = '#mD400: ' + error
            this.showError = true
            this.loading = false
            throw error;
          });
      }
    }
  }
</script>

<style>
</style>
