export default (value, format) => {
  let date
  if (value) {
    if (value.seconds) {
      date = new Date(value.seconds * 1000)
    } else {
      date = new Date(new Date(value).getTime())
    }
    if (date) {
      const options = {
        weekday: format.includes('TT') ? 'long' : format.includes('T') ? 'short' : undefined,
        year: format.includes('YYYY') ? 'numeric' : format.includes('YY') ? '2-digit' : undefined,
        month: format.includes('MMM') ? 'long' : format.includes('MM') ? 'short' : format.includes('M') ? 'numeric' : undefined,
        day: format.includes('DD') ? '2-digit' : format.includes('D') ? 'numeric' : undefined,
        hour: format.includes('hh') ? '2-digit' : format.includes('h') ? 'numeric' : undefined,
        minute: format.includes('mm') ? '2-digit' : format.includes('m') ? 'numeric' : undefined,
        second: format.includes('ss') ? '2-digit' : format.includes('s') ? 'numeric' : undefined
      }
      return date.toLocaleString('de-DE', options)
    }
  }
}
