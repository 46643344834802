<template>
  <v-container fluid>
    <v-row class="pa-2">
      <v-col cols="12">
        <v-toolbar dense flat rounded>
          <v-toolbar-title>Bewertungen ({{ bewertungen.length }})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-text-field
              v-model='search'
              append-icon='mdi-magnify'
              label='Suchen'
              single-line
              solo
              flat
              hide-details
              clearable
              clear-icon="mdi-close"
            ></v-text-field>
          </v-toolbar-items>
        </v-toolbar>
      </v-col>
      <v-col cols="12">
        <!-- <v-card class="mb-2 pt-1">
          <v-card-title>Statistik</v-card-title>
          <v-card-text>
            <span v-for="(item, key, index) in statistik" :key="index">
              <v-progress-linear :value="item / statistik.summe * 100" :rotate="-90" color="primary" size="80" height="24">
                {{ item }}
                {{ key }}
              </v-progress-linear>
            </span>
          </v-card-text>
        </v-card> -->
        <v-card class="mb-2 pa-2">
          <v-simple-table dense>
            <thead>
              <tr>
                <th width="20px">Nr.</th>
                <th>Name</th>
                <th>Fortschritt</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in juryFortschritt" :key="index">
                <td>{{ index + 1 }}.</td>
                <td>{{ item.anmeldeID | user(personen) }}</td>
                <td>
                  <v-progress-linear
                    :value="item.erledigt / item.gesamt * 100"
                    color="green"
                    height="25"
                  >
                  {{ item.erledigt }} von {{ item.gesamt }}
                  </v-progress-linear>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>

        <v-card class="mb-2 pa-2">
          <v-data-iterator
            dense
            :height="'calc(100vh - 352px)'"
            :headers="headers"
            :items="bewertungen"
            :search="search"
            fixed-header
            hide-default-footer
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            :items-per-page="-1"
            no-data-text="Keine Bewerbungen zugeordnet"
          >
            <template v-slot:default="{ items }">
              <v-row>
                <v-col
                  v-for="item in items"
                  :key="item.name"
                  cols="12"
                  sm="6"
                  md="2"
                  lg="2"
                  xl="1"
                >
                  <v-card @click="(showBewertung = true) && (bewertungData = item)">
                    <v-responsive>
                      <v-card-title class="pb-0 justify-center">
                        <v-chip label x-small>{{ item.anmeldeID | user(personen) }}</v-chip>
                        <v-chip label x-small class="mt-1">{{ item.bewerbungData.data.kategorie }}</v-chip>
                      </v-card-title>
                      <v-card-title class="py-1 justify-center">
                        <v-progress-circular :value="item.data.punkte.filter(punkt => punkt !== null && !isNaN(punkt)).length / 13 * 100" :rotate="-90" :color="item.data.punkte.filter(punkt => punkt !== null && !isNaN(punkt)).length === 13 ? 'green' : 'primary'" size="80" width="8" class="">
                          #{{ item.bewerbungData.nummer }}
                        </v-progress-circular>
                      </v-card-title>
                      <v-card-title class="py-1 justify-center">
                        <v-chip label small>{{ item.data.punkte.filter(punkt => punkt !== null && !isNaN(punkt)).reduce((sum, punkt) => sum + punkt, 0) }} Punkte</v-chip>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-actions class="mb-2 caption justify-center">{{ item.bewerbungData.institution }}</v-card-actions>
                    </v-responsive>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="bewertungData !== {}">
      <dialogBewertung :visible.sync="showBewertung" :data="bewertungData" @close="showBewertung = false"></dialogBewertung>
    </div>

    <v-dialog
      v-model="showError"
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'"
      scrollable
      persistent
    >
      <v-card>
        <v-system-bar class="warning" height="12"></v-system-bar>
        <v-card-text class="mt-6 text-center">
          {{ $t('mainDashboard.dialogerrorText') }} <br><a href="mailto:portal@german-sdg-award.de">portal@german-sdg-award.de</a>
          <br><br>
          {{ error }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="showError = false">{{ $t('schliessen') }}</v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import dialogBewertung from './dialogBewertung'
  export default {
    components: {
      dialogBewertung
    },
    name: 'juryPage',
    data() {
      return {
        showBewertung: false,
        bewertungData: {},
        loading: false,
        search: '',
        headers: [
          { number: '0', text: '', align: 'left', value: '' },
          { number: '1', text: 'Institution', align: 'left', value: 'institution' },
          { number: '2', text: 'Ansprechpartner', align: 'left', value: 'ansprechpartner' },
          { number: '3', text: 'Erstellt am', align: 'left', value: 'erstellt' },
          { number: '4', text: 'Abgesendet am', align: 'left', value: 'abgesendet' },
          { number: '5', text: 'Status', align: 'left', value: 'status' },
          { number: '6', text: '', align: 'left', value: '' },
          { number: '7', text: '', align: 'left', value: '' }
        ],
        showError: false,
        error: null
      }
    },
    computed: {
      user () {
        return this.$store.state.user.user
      },
      personen () {
        return this.$store.state.personen.personen
      },
      bewertungen () {
        let bewertungen
        if (['Administrator', 'Mitarbeiter'].includes(this.personen.find(item => item.anmeldeID === this.user).rolle)) { 
          bewertungen = this.$store.state.bewertungen.bewertungen
        } else {
          bewertungen = this.$store.state.bewertungen.bewertungen.filter(item => item.anmeldeID === this.user)
        }
        bewertungen.forEach(bewertung => {
          bewertung.bewerbungData = this.bewerbungen.find(item => item.id === bewertung.bewerbungID)
        })
        bewertungen.sort((a, b) => {
          const nummerA = a.bewerbungData?.nummer || Number.POSITIVE_INFINITY;
          const nummerB = b.bewerbungData?.nummer || Number.POSITIVE_INFINITY;
          return nummerA - nummerB;
        })
        return bewertungen
      },
      bewerbungen () {
        let bewerbungen = this.$store.state.bewerbungen.bewerbungen
        bewerbungen.forEach(bewerbung => {
          let person = this.$store.state.personen.personen.find(item => item.anmeldeID === bewerbung.anmeldeID)
          if (person) {
            bewerbung.institution = person.data.institution
            if (person.data.titel) {
              bewerbung.ansprechpartner = person.data.titel + ' ' + person.data.nachname + ', ' + person.data.vorname
            } else {
              bewerbung.ansprechpartner = person.data.nachname + ', ' + person.data.vorname
            }
          } else {
            bewerbung.institution = 'Keine Angabe'
            bewerbung.ansprechpartner = 'Keine Angabe'
          }
        })
        return bewerbungen
      },
      statistik () {
        let status = {
          entwurf: 0,
          abgeschlossen: 0,
          summe: 0
        }
        this.bewerbungen.forEach(item => {
          if (item.status === 'entwurf') {
            status.entwurf++
            status.summe++
          } else if (item.status === 'abgeschlossen') {
            status.abgeschlossen++
            status.summe++
          }
        })
        return status
      },
      juryFortschritt () {
        let fortschritt = []
        const groupedBewertungen = this.bewertungen.reduce((result, item) => {
          const anmeldeID = item.anmeldeID
          if (!result[anmeldeID]) {
            result[anmeldeID] = []
          }
          result[anmeldeID].push(item)
          return result
        }, {})
        for (const [anmeldeID, bewertungen] of Object.entries(groupedBewertungen)) {
          let count = 0
          bewertungen.forEach(item => {
            if (item.data.punkte.filter(punkt => punkt !== null && !isNaN(punkt)).length === 13) {
              count++
            }
          })
          fortschritt.push({
            anmeldeID: anmeldeID,
            erledigt: count,
            gesamt: bewertungen.length
          })
        }
        return fortschritt
      }
    },
    methods: {
      bewerbungDataComplete (data) {
        let count = 0
        let gesamt = 16
        if (data) {
          for (let prop in data.data) {
            if ((prop !== 'anhang') && prop !== 'globaleDimension') {
              if (data.data[prop]) {
                count++
              }
            }
          }
        }
        return (count / gesamt) ? (count / gesamt) : 0
      },
      pdfHerunterladen (item) {
        this.loading = true
        return this.$firebase.storage().ref(item.pdf_merge).getDownloadURL()
          .then(url => {
            const link = document.createElement('a')
            link.href = url
            link.target = '_blank'
            link.download = item.data.preis + ' Bewerbungsunterlagen_' + item.anmeldeID + '.pdf'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.loading = false
          })
          .catch((error) => {
            this.error = '#mD400: ' + error
            this.showError = true
            this.loading = false
            throw error;
          });
      }
    }
  }
</script>

<style>
</style>
