<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="10" offset-md="1" lg="10" offset-lg="1">
        <v-card rounded="xl" outlined class="text-center mb-4">
          <v-card-text>
            <v-btn text class="mr-3" :class="{ 'transparent': $i18n.locale !== 'de' }"
              @click="switchLanguage('de')"><img src="../../static/img/locales/de_DE.png" height="32"
                alt="de" /></v-btn>
            <v-btn text class="mr-3" :class="{ 'transparent': $i18n.locale !== 'en' }"
              @click="switchLanguage('en')"><img src="../../static/img/locales/en_GB.png" height="32"
                alt="en" /></v-btn>
            <v-btn text class="mr-3" :class="{ 'transparent': $i18n.locale !== 'fr' }"
              @click="switchLanguage('fr')"><img src="../../static/img/locales/fr_FR.png" height="32"
                alt="fr" /></v-btn>
          </v-card-text>
        </v-card>
        <v-card rounded="xl" outlined class="text-center pa-md-4">
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="mt-0 mt-md-4 mb-6 mb-md-0 ">
                <img class="hidden-sm-and-down" src="../../static/img/sdg_logo.png" width="50%" alt="logo" />
                <div :class="$vuetify.breakpoint.smAndDown ? 'text-h6' : 'text-h5'" class="pb-1 pt-2 pt-md-6">{{
              $t('bewerbungsportal') }}</div>
                <div :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4'"
                  class="font-weight-bold pb-2 pb-md-6">SDG Innovation Award 2024</div>
              </v-col>
              <v-col cols="12" md="6" class="mt-md-6">
                <v-card outlined class="mb-8">
                  <v-tabs grow v-model="tab" class="mb-0">
                    <v-tab>{{ $t('login.anmeldung') }}</v-tab>
                    <v-tab to="/registrieren">{{ $t('login.registrierung') }}</v-tab>
                  </v-tabs>
                </v-card>

                <v-text-field outlined dense name="email" :label="$t('login.emailadresse')" id="email" v-model="email"
                  type="email" required></v-text-field>
                <v-text-field outlined dense name="password" :label="$t('login.passwort')" id="password"
                  v-model="password" type="password" required></v-text-field>
                <v-btn block @click="onSignin()" class="primary" :disabled="loading" :loading="loading">
                  {{ $t('login.anmelden') }}
                </v-btn>
                <v-btn class="caption" block text @click="onDismissed()" to="/passwortvergessen">
                  {{ $t('login.passwortvergessen') }}
                </v-btn>
                <span v-if="error">
                  <span v-if="error.code !== undefined">
                    <v-alert class="mt-4" v-if="error.code === 'auth/user-not-found'" color="primary" border="left"
                      outlined dismissible>
                      {{ $t('login.error.user-not-found') }}
                    </v-alert>
                    <v-alert class="mt-4" v-if="error.code === 'auth/wrong-password'" color="primary" border="left"
                      outlined dismissible>
                      {{ $t('login.error.wrong-password') }}
                    </v-alert>
                    <v-alert class="mt-4"
                      v-if="(error.code !== 'auth/user-not-found') && (error.code !== 'auth/wrong-password')"
                      color="primary" border="left" outlined dismissible>
                      {{ $t('login.error.else') }} {{ error.code }} | {{ error.message }}
                    </v-alert>
                  </span>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'signIn',
  data() {
    return {
      tab: 0,
      loading: false,
      email: '',
      password: '',
      showAbout: false,
      showLoginInfo: false
    }
  },
  mounted() {
    this.$store.dispatch('user/clearError')
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    error() {
      return this.$store.state.user.error ? this.$store.state.user.error : undefined
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        if (this.$route.query !== '') {
          this.$router.push(this.$route.query.link)
        } else {
          this.$router.push('/')
        }
      }
    }
  },
  methods: {
    onSignin() {
      this.$store.dispatch('user/signUserIn', { email: this.email, password: this.password })
      this.$router.push('/')
    },
    onDismissed() {
      this.$store.dispatch('user/clearError')
    },
    switchLanguage(language) {
      this.$i18n.locale = language;
    }
  },
  beforeMount() {
    if (this.$firebase.auth().currentUser) {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.container {
  padding-left: 16px;
}
</style>
