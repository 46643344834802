export default (value, users) => {
  if (value) {
    let nutzer = 'Kein Kontakt gefunden'
    let user = users.find(user => user.anmeldeID === value)
    if (user) {
      if (user.data.titel) {
        nutzer = user.data.titel + ' ' + user.data.nachname + ', ' + user.data.vorname
      } else {
        nutzer = user.data.nachname + ', ' + user.data.vorname
      }
    }
    return nutzer
  } else {
    return
  }
}
