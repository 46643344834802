import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  bewerbungen: [],
  loading: false,
  loaded: false,
  error: null
}

// getters
const getters = {
  bewerbungen (state) {
    return state.bewerbungen
  },
  bewerbungUID: (state) => (id) => {
    return state.bewerbungen.find(bewerbung => bewerbung.anmeldeID === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadBewerbungen ({commit}) {
    commit('setLoading', true)
    console.log('Bewerbungen laden...')
    firebase.firestore().collection('jahre').doc('2024').collection('bewerbungen').where('erstellt', '>', new Date('2024-05-01')).onSnapshot(snapshot => {
      const bewerbungen = []
      snapshot.forEach(doc => {
        if (!doc.data().delete) {
          bewerbungen.push({
            ...doc.data(),
            id: doc.id
          })
        }
        commit('setLoadedBewerbungen', bewerbungen)
        commit('setLoading', false)
        commit('setLoaded', true)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  createBewerbung (context, payload) {
    firebase.firestore().collection('jahre').doc('2024').collection('bewerbungen').add(payload)
      .catch((error) => {
        console.log(error)
      })
  },
  deleteBewerbungData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('jahre').doc('2024').collection('bewerbungen').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteBewerbung', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateBewerbungData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('jahre').doc('2024').collection('bewerbungen').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateBewerbung', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedBewerbungen (state, payload) {
    state.bewerbungen = payload
  },
  createBewerbung (state, payload) {
    state.bewerbungen.push(payload)
  },
  updateBewerbung (state, payload) {
    const bewerbung = state.bewerbungen.find(bewerbung => {
      return bewerbung.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        bewerbung[key] = payload[key]
      }
    })
  },
  deleteBewerbung (state, payload) {
    let array = state.bewerbungen
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.bewerbungen = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setLoaded (state, payload) {
    state.loaded = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
