<!-- eslint-disable -->
<template>
  <v-container fluid>
    <v-row class="pa-2">
      <v-col cols="12">
        <v-toolbar dense flat rounded>
          <v-toolbar-title>Statistik</v-toolbar-title>
          <v-btn @click="reloadMap()"><v-icon>mdi-refresh</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-text-field v-model='search' append-icon='mdi-magnify' label='Suchen' single-line solo flat hide-details
              clearable clear-icon="mdi-close"></v-text-field>
          </v-toolbar-items>
        </v-toolbar>
      </v-col>
      <v-col cols="12">
        <JSC :options="chartConfig" class="columnChart"></JSC>
      </v-col>
      <v-col cols="6">
        <v-card class="mb-6">
          <JSC :options="chartConfigDE" class="columnChart"></JSC>
        </v-card>
        <v-card>
          <v-card-title>Nicht zuordbare Bewerbungen</v-card-title>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Land</th>
                <th>Stadt</th>
                <th>Sprache</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in groupData.undefinedCountries" :key="index">
                <td>{{ item.country }}</td>
                <td>{{ item.city }}</td>
                <td>{{ item.language }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>Bewerbungen pro Land</v-card-title>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Land</th>
                <th>Anzahl</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in groupData.laender" :key="index">
                <td>{{ item.country }}</td>
                <td>{{ item.anzahl }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JSC from 'jscharting-vue';
export default {
  name: 'statsPage',
  data() {
    return {
      loading: false,
      search: undefined,
      countries: [
        { name: "Afghanistan", code: "AF", german: "Afghanistan" },
        { name: "Albania", code: "AL", german: "Albanien" },
        { name: "Algeria", code: "DZ", german: "Algerien" },
        { name: "Andorra", code: "AD", german: "Andorra" },
        { name: "Angola", code: "AO", german: "Angola" },
        { name: "Antigua and Barbuda", code: "AG", german: "Antigua und Barbuda" },
        { name: "Argentina", code: "AR", german: "Argentinien" },
        { name: "Armenia", code: "AM", german: "Armenien" },
        { name: "Australia", code: "AU", german: "Australien" },
        { name: "Austria", code: "AT", german: "Österreich" },
        { name: "Azerbaijan", code: "AZ", german: "Aserbaidschan" },
        { name: "Bahamas", code: "BS", german: "Bahamas" },
        { name: "Bahrain", code: "BH", german: "Bahrain" },
        { name: "Bangladesh", code: "BD", german: "Bangladesch" },
        { name: "Barbados", code: "BB", german: "Barbados" },
        { name: "Belarus", code: "BY", german: "Belarus" },
        { name: "Belgium", code: "BE", german: "Belgien" },
        { name: "Belize", code: "BZ", german: "Belize" },
        { name: "Benin", code: "BJ", german: "Benin" },
        { name: "Bhutan", code: "BT", german: "Bhutan" },
        { name: "Bolivia", code: "BO", german: "Bolivien" },
        { name: "Bosnia and Herzegovina", code: "BA", german: "Bosnien und Herzegowina" },
        { name: "Botswana", code: "BW", german: "Botswana" },
        { name: "Brazil", code: "BR", german: "Brasilien" },
        { name: "Brunei Darussalam", code: "BN", german: "Brunei Darussalam" },
        { name: "Bulgaria", code: "BG", german: "Bulgarien" },
        { name: "Burkina Faso", code: "BF", german: "Burkina Faso" },
        { name: "Burundi", code: "BI", german: "Burundi" },
        { name: "Cabo Verde", code: "CV", german: "Cabo Verde" },
        { name: "Cambodia", code: "KH", german: "Kambodscha" },
        { name: "Cameroon", code: "CM", german: "Kamerun" },
        { name: "Canada", code: "CA", german: "Kanada" },
        { name: "Central African Republic", code: "CF", german: "Zentralafrikanische Republik" },
        { name: "Chad", code: "TD", german: "Tschad" },
        { name: "Chile", code: "CL", german: "Chile" },
        { name: "China", code: "CN", german: "China" },
        { name: "Colombia", code: "CO", german: "Kolumbien" },
        { name: "Comoros", code: "KM", german: "Komoren" },
        { name: "Congo", code: "CG", german: "Kongo" },
        { name: "Costa Rica", code: "CR", german: "Costa Rica" },
        { name: "Croatia", code: "HR", german: "Kroatien" },
        { name: "Cuba", code: "CU", german: "Kuba" },
        { name: "Cyprus", code: "CY", german: "Zypern" },
        { name: "Czech Republic", code: "CZ", german: "Tschechien" },
        { name: "Denmark", code: "DK", german: "Dänemark" },
        { name: "Djibouti", code: "DJ", german: "Dschibuti" },
        { name: "Dominica", code: "DM", german: "Dominica" },
        { name: "Dominican Republic", code: "DO", german: "Dominikanische Republik" },
        { name: "Ecuador", code: "EC", german: "Ecuador" },
        { name: "Egypt", code: "EG", german: "Ägypten" },
        { name: "El Salvador", code: "SV", german: "El Salvador" },
        { name: "Estonia", code: "EE", german: "Estland" },
        { name: "Eswatini", code: "SZ", german: "Eswatini" },
        { name: "Ethiopia", code: "ET", german: "Äthiopien" },
        { name: "Fiji", code: "FJ", german: "Fidschi" },
        { name: "Finland", code: "FI", german: "Finnland" },
        { name: "France", code: "FR", german: "Frankreich" },
        { name: "Gabon", code: "GA", german: "Gabun" },
        { name: "Gambia", code: "GM", german: "Gambia" },
        { name: "Georgia", code: "GE", german: "Georgien" },
        { name: "Germany", code: "DE", german: "Deutschland" },
        { name: "Ghana", code: "GH", german: "Ghana" },
        { name: "Greece", code: "GR", german: "Griechenland" },
        { name: "Grenada", code: "GD", german: "Grenada" },
        { name: "Guatemala", code: "GT", german: "Guatemala" },
        { name: "Guinea", code: "GN", german: "Guinea" },
        { name: "Guyana", code: "GY", german: "Guyana" },
        { name: "Haiti", code: "HT", german: "Haiti" },
        { name: "Honduras", code: "HN", german: "Honduras" },
        { name: "Hungary", code: "HU", german: "Ungarn" },
        { name: "Iceland", code: "IS", german: "Island" },
        { name: "India", code: "IN", german: "Indien" },
        { name: "Indonesia", code: "ID", german: "Indonesien" },
        { name: "Iran", code: "IR", german: "Iran" },
        { name: "Iraq", code: "IQ", german: "Irak" },
        { name: "Ireland", code: "IE", german: "Irland" },
        { name: "Israel", code: "IL", german: "Israel" },
        { name: "Italy", code: "IT", german: "Italien" },
        { name: "Jamaica", code: "JM", german: "Jamaika" },
        { name: "Japan", code: "JP", german: "Japan" },
        { name: "Jordan", code: "JO", german: "Jordanien" },
        { name: "Kazakhstan", code: "KZ", german: "Kasachstan" },
        { name: "Kenya", code: "KE", german: "Kenia" },
        { name: "Kiribati", code: "KI", german: "Kiribati" },
        { name: "Korea (North)", code: "KP", german: "Nordkorea" },
        { name: "Korea (South)", code: "KR", german: "Südkorea" },
        { name: "Kuwait", code: "KW", german: "Kuwait" },
        { name: "Kyrgyzstan", code: "KG", german: "Kirgisistan" },
        { name: "Laos", code: "LA", german: "Laos" },
        { name: "Latvia", code: "LV", german: "Lettland" },
        { name: "Lebanon", code: "LB", german: "Libanon" },
        { name: "Lesotho", code: "LS", german: "Lesotho" },
        { name: "Liberia", code: "LR", german: "Liberia" },
        { name: "Libya", code: "LY", german: "Libyen" },
        { name: "Liechtenstein", code: "LI", german: "Liechtenstein" },
        { name: "Lithuania", code: "LT", german: "Litauen" },
        { name: "Luxembourg", code: "LU", german: "Luxemburg" },
        { name: "Madagascar", code: "MG", german: "Madagaskar" },
        { name: "Malawi", code: "MW", german: "Malawi" },
        { name: "Malaysia", code: "MY", german: "Malaysia" },
        { name: "Maldives", code: "MV", german: "Malediven" },
        { name: "Mali", code: "ML", german: "Mali" },
        { name: "Malta", code: "MT", german: "Malta" },
        { name: "Marshall Islands", code: "MH", german: "Marshallinseln" },
        { name: "Mauritania", code: "MR", german: "Mauretanien" },
        { name: "Mauritius", code: "MU", german: "Mauritius" },
        { name: "Mexico", code: "MX", german: "Mexiko" },
        { name: "Micronesia", code: "FM", german: "Mikronesien" },
        { name: "Moldova", code: "MD", german: "Moldawien" },
        { name: "Monaco", code: "MC", german: "Monaco" },
        { name: "Mongolia", code: "MN", german: "Mongolei" },
        { name: "Montenegro", code: "ME", german: "Montenegro" },
        { name: "Morocco", code: "MA", german: "Marokko" },
        { name: "Mozambique", code: "MZ", german: "Mosambik" },
        { name: "Myanmar", code: "MM", german: "Myanmar" },
        { name: "Namibia", code: "NA", german: "Namibia" },
        { name: "Nauru", code: "NR", german: "Nauru" },
        { name: "Nepal", code: "NP", german: "Nepal" },
        { name: "Netherlands", code: "NL", german: "Niederlande" },
        { name: "New Zealand", code: "NZ", german: "Neuseeland" },
        { name: "Nicaragua", code: "NI", german: "Nicaragua" },
        { name: "Niger", code: "NE", german: "Niger" },
        { name: "Nigeria", code: "NG", german: "Nigeria" },
        { name: "North Macedonia", code: "MK", german: "Nordmazedonien" },
        { name: "Norway", code: "NO", german: "Norwegen" },
        { name: "Oman", code: "OM", german: "Oman" },
        { name: "Pakistan", code: "PK", german: "Pakistan" },
        { name: "Palau", code: "PW", german: "Palau" },
        { name: "Palestine", code: "PS", german: "Palästina" },
        { name: "Panama", code: "PA", german: "Panama" },
        { name: "Papua New Guinea", code: "PG", german: "Papua-Neuguinea" },
        { name: "Paraguay", code: "PY", german: "Paraguay" },
        { name: "Peru", code: "PE", german: "Peru" },
        { name: "Philippines", code: "PH", german: "Philippinen" },
        { name: "Poland", code: "PL", german: "Polen" },
        { name: "Portugal", code: "PT", german: "Portugal" },
        { name: "Qatar", code: "QA", german: "Katar" },
        { name: "Romania", code: "RO", german: "Rumänien" },
        { name: "Russia", code: "RU", german: "Russland" },
        { name: "Rwanda", code: "RW", german: "Ruanda" },
        { name: "Saint Kitts and Nevis", code: "KN", german: "St. Kitts und Nevis" },
        { name: "Saint Lucia", code: "LC", german: "St. Lucia" },
        { name: "Saint Vincent and the Grenadines", code: "VC", german: "St. Vincent und die Grenadinen" },
        { name: "Samoa", code: "WS", german: "Samoa" },
        { name: "San Marino", code: "SM", german: "San Marino" },
        { name: "Sao Tome and Principe", code: "ST", german: "São Tomé und Príncipe" },
        { name: "Saudi Arabia", code: "SA", german: "Saudi-Arabien" },
        { name: "Senegal", code: "SN", german: "Senegal" },
        { name: "Serbia", code: "RS", german: "Serbien" },
        { name: "Seychelles", code: "SC", german: "Seychellen" },
        { name: "Sierra Leone", code: "SL", german: "Sierra Leone" },
        { name: "Singapore", code: "SG", german: "Singapur" },
        { name: "Slovakia", code: "SK", german: "Slowakei" },
        { name: "Slovenia", code: "SI", german: "Slowenien" },
        { name: "Solomon Islands", code: "SB", german: "Salomonen" },
        { name: "Somalia", code: "SO", german: "Somalia" },
        { name: "South Africa", code: "ZA", german: "Südafrika" },
        { name: "South Sudan", code: "SS", german: "Südsudan" },
        { name: "Spain", code: "ES", german: "Spanien" },
        { name: "Sri Lanka", code: "LK", german: "Sri Lanka" },
        { name: "Sudan", code: "SD", german: "Sudan" },
        { name: "Suriname", code: "SR", german: "Suriname" },
        { name: "Sweden", code: "SE", german: "Schweden" },
        { name: "Switzerland", code: "CH", german: "Schweiz" },
        { name: "Syria", code: "SY", german: "Syrien" },
        { name: "Taiwan", code: "TW", german: "Taiwan" },
        { name: "Tajikistan", code: "TJ", german: "Tadschikistan" },
        { name: "Tanzania", code: "TZ", german: "Tansania" },
        { name: "Thailand", code: "TH", german: "Thailand" },
        { name: "Timor-Leste", code: "TL", german: "Osttimor" },
        { name: "Togo", code: "TG", german: "Togo" },
        { name: "Tonga", code: "TO", german: "Tonga" },
        { name: "Trinidad and Tobago", code: "TT", german: "Trinidad und Tobago" },
        { name: "Tunisia", code: "TN", german: "Tunesien" },
        { name: "Turkey", code: "TR", german: "Türkei" },
        { name: "Turkmenistan", code: "TM", german: "Turkmenistan" },
        { name: "Tuvalu", code: "TV", german: "Tuvalu" },
        { name: "Uganda", code: "UG", german: "Uganda" },
        { name: "Ukraine", code: "UA", german: "Ukraine" },
        { name: "United Arab Emirates", code: "AE", german: "Vereinigte Arabische Emirate" },
        { name: "United Kingdom", code: "GB", german: "Vereinigtes Königreich" },
        { name: "United States", code: "US", german: "USA" },
        { name: "Uruguay", code: "UY", german: "Uruguay" },
        { name: "Uzbekistan", code: "UZ", german: "Usbekistan" },
        { name: "Vanuatu", code: "VU", german: "Vanuatu" },
        { name: "Vatican City", code: "VA", german: "Vatikanstadt" },
        { name: "Venezuela", code: "VE", german: "Venezuela" },
        { name: "Vietnam", code: "VN", german: "Vietnam" },
        { name: "Yemen", code: "YE", german: "Jemen" },
        { name: "Zambia", code: "ZM", german: "Sambia" },
        { name: "Zimbabwe", code: "ZW", german: "Simbabwe" }
      ],
      chartConfig: {
        debug: false,
        height: 500,
        type: 'map',
        legend_visible: false,
        chartArea: { outline_color: '#bbbbbb', fill: 'white' },
        /* Disable zooming */
        palette: {
          colors: ['#203569', '#203569', '#203569', '#203569', '#203569', '#203569', '#203569']
        },
        defaultPoint: {
            tooltip: '%name:<br>Anzahl: <b>%zValue</b>',
          },
        defaultTooltip: {
          fill: 'blue',
          label_color: 'white',
          outline_width: 0
        },
        mapping_base_layers: 'world',
        mapping: {
          projection: false
        },
        series: [{ map: "de" }, { points: [] }]
      },
      chartConfigDE: {
        debug: false,
        height: 600,
        type: 'map',
        legend_visible: false,
        chartArea: { outline_color: '#bbbbbb', fill: 'white' },
        /* Disable zooming */
        palette: {
          colors: ['#203569', '#203569', '#203569', '#203569', '#203569', '#203569', '#203569']
        },
        defaultPoint: {
            tooltip: '%name:<br>Anzahl: <b>%zValue</b>',
          },
        defaultTooltip: {
          fill: 'blue',
          label_color: 'white',
          outline_width: 0
        },
        mapping_base_layers: 'europe',
        mapping: {
          projection: false
        },
        series: [{ map: "de" }]
      },
    }
  },
  components: {
    JSC
  },
  computed: {
    groupData() {
      const laenderMap = {};
      const staedteMap = {};
      const languageMap = {};
      const undefinedCountries = []

      this.bewerbungen.forEach(({ code, country, city, language }) => {
        // Länder gruppieren
        country = country.trim()
        if (code === 'undefined') {
          undefinedCountries.push({ code, country, city, language })
        }
        if (!laenderMap[country]) {
          laenderMap[country] = { code, country, anzahl: 0 };
        }
        laenderMap[country].anzahl += 1;

        // Städte gruppieren
        const cityKey = `${country}-${city}`;
        if (!staedteMap[cityKey]) {
          staedteMap[cityKey] = { code, country, city, anzahl: 0 };
        }
        staedteMap[cityKey].anzahl += 1;

        // Sprachen gruppieren
        if (language) {
          if (!languageMap[language]) {
            languageMap[language] = { language, anzahl: 0 };
          }
          languageMap[language].anzahl += 1;
        }
      });

      return {
        laender: Object.values(laenderMap).sort((a, b) => b.anzahl - a.anzahl),
        staedte: Object.values(staedteMap).sort((a, b) => b.anzahl - a.anzahl),
        language: Object.values(languageMap).sort((a, b) => b.anzahl - a.anzahl),
        undefinedCountries
      }
    },
    bewerbungen() {
      let bewerbungen = this.$store.state.bewerbungen.bewerbungen.filter(item => item.status === 'Abgesendet');
      bewerbungen.forEach(bewerbung => {
        let person = this.$store.state.personen.personen.find(item => item.anmeldeID === bewerbung.anmeldeID)
        if (person) {
          bewerbung.person = person.data
          bewerbung.institution = person.data.institution
          if (person.data.titel) {
            bewerbung.ansprechpartner = person.data.titel + ' ' + person.data.nachname + ', ' + person.data.vorname
          } else {
            bewerbung.ansprechpartner = person.data.nachname + ', ' + person.data.vorname
          }
        } else {
          bewerbung.institution = 'Keine Angabe'
          bewerbung.ansprechpartner = 'Keine Angabe'
        }
      })
      const retArray = []
      bewerbungen.forEach(bewerbung => {
        const land = bewerbung.person.land ? bewerbung.person.land.trim() : undefined;
        const countryData = this.countries.find(
          country => country.name.toLowerCase() === land?.toLowerCase() || country.german.toLowerCase() === land?.toLowerCase()
        );
        retArray.push({
          code: countryData ? countryData.code : "undefined",
          country: countryData ? countryData.german : bewerbung.person.land,
          city: bewerbung.person.ort || bewerbung.person.ort,
          language: bewerbung.person.language || 'undefined'
        })
      })
      return retArray
    }
  },
  methods: {
    reloadMap() {
      this.groupData.laender.forEach(land => {
        this.chartConfig.series.push({
          points: [{
            map: land.code.toLowerCase(),
            z: land.anzahl
          }]
        })
      })
    }
  },
  mounted() {
    this.chartConfig.series = [{ points: [] }]
    this.reloadMap()
  }
}
</script>

<style></style>
