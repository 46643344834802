import { store } from '../store'
import 'firebase/compat/auth'

export default (to, from, next) => {
  if (store.state.user.user) {
    next()
  } else {
    next('/anmelden?link=' + to.path)
  }
}
