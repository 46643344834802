// ****************************************************************
// Definition globale Variablen
// ****************************************************************

export default {
  snackbar: {
    show: false,
    text: ''
  }
}
