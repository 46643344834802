import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  personen: [],
  loading: false,
  loaded: false,
  error: null
}

// getters
const getters = {
  personen (state) {
    return state.personen
  },
  personUID: (state) => (id) => {
    return state.personen.find(person => person.anmeldeID === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadPersonen ({commit}) {
    commit('setLoading', true)
    console.log('Personen laden...')
    firebase.firestore().collection('personen').onSnapshot(snapshot => {
      const personen = []
      snapshot.forEach(doc => {
        if (!doc.data().delete) {
          personen.push({
            ...doc.data(),
            id: doc.id
          })
        }
        commit('setLoadedPersonen', personen)
        commit('setLoading', false)
        commit('setLoaded', true)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  createPerson (context, payload) {
    firebase.firestore().collection('personen').add(payload)
      .catch((error) => {
        console.log(error)
      })
  },
  deletePersonData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('personen').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deletePerson', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updatePersonData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('personen').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updatePerson', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedPersonen (state, payload) {
    state.personen = payload
  },
  createPerson (state, payload) {
    state.personen.push(payload)
  },
  updatePerson (state, payload) {
    const person = state.personen.find(person => {
      return person.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        person[key] = payload[key]
      }
    })
  },
  deletePerson (state, payload) {
    let array = state.personen
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.personen = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setLoaded (state, payload) {
    state.loaded = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
