<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="10" offset-md="1" lg="10" offset-lg="1">
        <v-card rounded="xl" outlined class="text-center pa-md-4">
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="mt-0 mt-md-4 mb-6 mb-md-0 ">
                <img class="hidden-sm-and-down" src="../../static/img/sdg_logo.png" width="50%" alt="logo"/>
                <div :class="$vuetify.breakpoint.smAndDown ? 'text-h6' : 'text-h5'" class="pb-1 pt-2 pt-md-6">{{ $t('bewerbungsportal') }}</div>
                <div :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4'" class="font-weight-bold pb-2 pb-md-6">SDG-Award</div>
              </v-col>
              <v-col cols="12" md="6" class="mt-md-6">
                <v-card outlined class="mb-8">
                  <v-tabs grow v-model="tab" class="mb-0">
                    <v-tab>{{ $t('login.passwortvergessen') }}</v-tab>
                  </v-tabs>
                </v-card>

                <v-text-field outlined dense name="email" :label="$t('login.emailadresse')" id="email" v-model="email" type="email" required></v-text-field>
                <v-btn block @click="onSignin()" class="primary" :disabled="loading" :loading="loading">
                  {{ $t('login.passwortreset') }}
                </v-btn>
                <v-btn class="caption" block text @click="onDismissed()" to="/anmelden">
                  {{ $t('login.anmeldung') }}
                </v-btn>
                <span v-if="error">
                  <span v-if="error.code !== undefined">
                    <v-alert class="mt-4" v-if="error.code === 'auth/user-not-found'" color="primary" border="left" outlined dismissible>
                      {{ $t('login.error.user-not-found') }}
                    </v-alert>
                    <v-alert class="mt-4" v-if="error.code === 'auth/wrong-password'" color="primary" border="left" outlined dismissible>
                      {{ $t('login.error.wrong-password') }}
                    </v-alert>
                    <v-alert class="mt-4" v-if="error.code === 'reset-success'" color="primary" border="left" outlined dismissible>
                      {{ $t('login.error.reset-sucess') }}
                    </v-alert>
                    <v-alert class="mt-4" v-if="(error.code !== 'reset-success') && (error.code !== 'auth/user-not-found') && (error.code !== 'auth/wrong-password')" color="primary" border="left" outlined dismissible>
                      {{ $t('login.error.else') }} {{ error.code }} | {{ error.message }}
                    </v-alert>
                  </span>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data () {
      return {
        email: '',
        loading: false
      }
    },
    computed: mapState({
      user: state => state.user.user,
      error: state => state.user.error
    }),
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },
    methods: {
      onSignin () {
        this.$store.dispatch('user/resetPassword', {email: this.email})
      },
      onDismissed () {
        this.$store.dispatch('user/clearError')
      }
    }
  }
</script>

<style scoped>
.container{
  padding-left: 16px;
}
</style>
