import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'


var firebaseConfig = {
  apiKey: 'AIzaSyBbCTreVINBMy_duuz576p6gF7OUh9D2n8',
  authDomain: 'sdg-award.firebaseapp.com',
  projectId: 'sdg-award',
  storageBucket: 'sdg-award.appspot.com',
  messagingSenderId: '303447444459',
  appId: '1:303447444459:web:98b92adde1b1a99a8c67ae',
  measurementId: 'G-H8LKQQLKWM'
}

// Initialisieren Sie Firebase
firebase.initializeApp(firebaseConfig)

export default firebase