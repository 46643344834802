<template>
  <v-container fluid>
    <v-row class="mt-8">
      <v-col cols="12" md="6" offset="3">
        <v-card rounded="xl" outlined class="text-center pa-4">
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="10" offset-sm="1">
                <img src="/static/img/sdg_logo.png" width="50%" alt="logo"/>
                <div class="text-h3 font-weight-bold pt-6">German SDG-Award</div>
                <div class="text-h5 pt-3 pb-6">Bewerbungsportal</div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title class="justify-center">Mailadresse muss verifiziert werden</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="mt-2" sm="10" offset-sm="1">
                die E-Mailadresse <span class="font-weight-bold">{{ mail }}</span> wurde bisher noch nicht bestätigt. <br>
                Um auf das Bewerbungsportal zuzgreifen müssen Sie Ihre E-Mailadresse
                bestätigen, in dem Sie auf den Verifizierungslink in der zugesendeten Mail klicken. Sollten
                Sie keine Mail bekommen haben, können Sie siche ine neue Mail zusenden lassen.<br><br>
                <v-btn block class="primary" @click="sendVerifizierungsMail()">Mail erneut senden</v-btn>
                <v-btn block outlined class="mt-2" @click="reload()">Ich habe bestätigt</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    computed: {
      mail () {
        if (this.$firebase.auth().currentUser) {
          return this.$firebase.auth().currentUser.email
        } else {
          return null
        }
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/registrieren')
        }
      }
    },
    methods: {
      sendVerifizierungsMail () {
        let user = this.$firebase.auth().currentUser
        if (!this.$firebase.auth().currentUser.emailVerified) {
          user.sendEmailVerification().then(function () {
            console.log('Verification Mail send')
          }).catch(error => {
            console.log(error)
          })
        }
        return user
      },
      reload () {
        this.$router.push('/')
      }
    }
    // beforeMount () {
    //   this.sendVerifizierungsMail()
    // }
  }
</script>

<style scoped>
.container{
  padding-left: 16px;
}
</style>
