import JSPDF from 'jspdf'

// Import der Schriftarten
import '../../static/fonts/Oswald-normal.js'
import '../../static/fonts/Roboto-Bold-bold.js'
import '../../static/fonts/Roboto-Regular-normal.js'
import headerIMG from '../../static/img/header_PDF.png'
// import logoIMG from '../../static/img/sdg_award_logo.png'
import footerIMG from '../../static/img/sdg_award_logo.png'

import firebase from 'firebase/compat/app'

export async function header(doc, person, randLinks, seite, vorschau, footer) {
  // doc.setFillColor(21, 59, 100)
  // doc.rect(0, 0, 210, 40, 'F')
  // doc.setTextColor(21, 59, 100)
  let img = new Image()
  img.src = headerIMG
  doc.addImage(img, 0, 0, 210, 45)
  doc.setTextColor(255, 255, 255)
  doc.setFontSize(22)
  doc.setFont('Oswald', 'normal')
  doc.text('Bewerbungsunterlagen', randLinks, 22)
  doc.setFontSize(15)
  doc.text(person.institution, randLinks, 32)
  if (seite === 1 || footer) {
    doc.setFillColor(21, 59, 100)
    doc.rect(0, 255, 210, 1, 'F')
    img.src = footerIMG
    doc.addImage(img, 65, 265, 80, 25.2)
    doc.setFontSize(11)
    doc.setFont('Roboto', 'normal')
    doc.text(String(seite), 185, 290.2, { align: "right" })
  }
  if (vorschau) {
    doc.setFillColor(0, 0, 0)
    doc.setFontSize(16)
    doc.text('VORSCHAU', 100, 100)
  }
}

export function printBewerbung (item, person, vorschau) {
  const bewerbung = item.data
  let doc = new JSPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4'
  })
  const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  const formattedPrintDate = new Intl.DateTimeFormat('de-DE', options).format(new Date());
  // const date = new Date(item.log[item.log.length - 1].date.seconds * 1000)
  // const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  // const formattedDate = new Intl.DateTimeFormat('de-DE', options).format(date);
  for (let key in bewerbung) {
    if (typeof bewerbung[key] === 'string') {
      // Ersetze Tabs durch Leerzeichen in Strings
      bewerbung[key] = bewerbung[key].replace(/\t/g, ' ');
    }
  }
  const printData = [
    { art: 'meta', text: 'PDF generiert am ' + formattedPrintDate + ' Uhr' },
    { art: 'header', text: 'Bewerberinformationen' },
    { art: 'text', beschreibung: 'Bewerbungs-ID', text: item.id },
    // { art: 'text', beschreibung: 'Bewerbungs-Nummer', text: item.nummer ? item.nummer.toString() : '#' },
    // { art: 'text', beschreibung: 'Eingereicht am', text: formattedDate + ' Uhr' },
    { art: 'text', beschreibung: 'Bewerbungs-Preis', text: bewerbung.preis ? bewerbung.preis : '#' },
    { art: 'text', beschreibung: 'Bewerbungs-Kategorie', text: bewerbung.kategorie ? bewerbung.kategorie : '#' },
    { art: 'text', beschreibung: 'Unternehmen', text: person.institution ? person.institution : '#' },
    { art: 'text', beschreibung: '', text: person.webseite ? person.webseite : '#' },
    { art: 'text', beschreibung: '', text: person.adresse ? person.adresse + ' ' + (person.adresszusatz ? person.adresszusatz : '') : '#' },
    { art: 'text', beschreibung: '', text: person.adresse ? person.plz + ' ' + person.ort + ' ' + person.land : '#' },
    { art: 'text', beschreibung: 'Vorsitzender', text: person.ceotitel ? person.ceoanrede + ' ' +  person.ceotitel + ' ' + person.ceovorname + ' ' + person.ceonachname : person.ceoanrede + ' ' + person.ceovorname + ' ' + person.ceonachname },
    { art: 'text', beschreibung: '', text: person.ceotelefonnummer ? person.ceotelefonnummer : '#' },
    { art: 'text', beschreibung: '', text: person.ceomail ? person.ceomail : '#' },
    { art: 'text', beschreibung: 'Ansprechpartner', text: person.titel ? person.anrede + ' ' +  person.titel + ' ' + person.vorname + ' ' + person.nachname : person.anrede + ' ' + person.vorname + ' ' + person.nachname },
    { art: 'text', beschreibung: '', text: person.telefonnummer ? person.telefonnummer : '#' },
    { art: 'text', beschreibung: '', text: person.mail ? person.mail : '#' },
    { art: 'newPage' },
    { art: 'header', text: 'Bewerbungsunterlagen' },
    { art: 'titel', text: 'Beitrag' },
    { art: 'bool', beschreibung: 'Zu welchen SDGs trägt Ihre Unternehmung bei?', text: bewerbung.beitrag, prop: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17'] },
    { art: 'text_lang', beschreibung: 'Begründung', text: bewerbung.textBeitrag },
    { art: 'titel', text: 'Zielsetzung' },
    { art: 'text_lang', beschreibung: '', text: bewerbung.textZielsetzung },
    { art: 'titel', text: 'Zielerreichung' },
    { art: 'text_lang', beschreibung: '', text: bewerbung.textZielerreichung },
    { art: 'titel', text: 'Fortschritt' },
    { art: 'text', beschreibung: 'Fortschritt', text: bewerbung.fortschritt },
    { art: 'titel', text: 'Motivation zur Mitwirkung' },
    { art: 'text', beschreibung: 'Initiative gestartet', text: bewerbung.selectMitwirkung },
    { art: 'text_lang', beschreibung: '', text: bewerbung.textMitwirkung },
    { art: 'titel', text: 'Übertragbarkeit bzw. Multiplizierbarkeit' },
    { art: 'bool', beschreibung: '', text: bewerbung.uebertragbarkeit, prop: ['Industrieländer', 'Schwellenländer', 'Entwicklungsländer', 'Nicht übertragbar',] },
    { art: 'text_lang', beschreibung: 'Erläuterung der Übertragbarkeit', text: bewerbung.textUebertragbarkeit },
    { art: 'text_lang', beschreibung: 'Aktivitäten zum Transfer', text: bewerbung.textUebertragbarkeitTransfer },
    { art: 'titel', text: 'Kooperation' },
    { art: 'text', beschreibung: 'Kooperation', text: bewerbung.kooperation },
    { art: 'text_lang', beschreibung: 'Erläuterung der Kooperation', text: bewerbung.textKooperation },
    { art: 'titel', text: 'Reichweite' },
    { art: 'text', beschreibung: 'Reichweite', text: bewerbung.reichweite },
    { art: 'text_lang', beschreibung: 'Erläuterung der Reichweite', text: bewerbung.textReichweite },
    { art: 'titel', text: 'Wirkung' },
    { art: 'text_lang', beschreibung: 'Welche Wirkung hat die Aktivität auf die SDGs?', text: bewerbung.textWirkung },
    { art: 'text_lang', beschreibung: 'Internationale Wirksamkeit der Aktivität', text: bewerbung.textWirkungInternational },
    { art: 'titel', text: 'Messbarkeit' },
    { art: 'text', beschreibung: 'Messbarkeit', text: bewerbung.messbarkeit },
    { art: 'text_lang', beschreibung: 'Erläuterung der Messbarkeit', text: bewerbung.textMessbarkeit },
    { art: 'text_lang', beschreibung: 'Beispiele für Indikatoren', text: bewerbung.textMessbarkeitIndikatoren },
    { art: 'text', beschreibung: 'Zertifikate vorhanden', text: bewerbung.messbarkeitZertifikate },
    { art: 'text_lang', beschreibung: 'Erläuterung Zertifikate', text: bewerbung.textmessbarkeitZertifikate },
    // { art: 'titel', text: 'Kosten' },
    // { art: 'text', beschreibung: 'Kosten', text: bewerbung.kosten+ ' €'  },
    // { art: 'text', beschreibung: 'Anteil öffentlich', text: bewerbung.kosten_oeffentlich + ' %' },
    // { art: 'text', beschreibung: 'Anteil privat', text: bewerbung.kosten_privat + ' %' },
    { art: 'newPage' },
    { art: 'header', text: 'Anhänge' }
  ]

  let seite = 1
  let randOben = 55
  let randLinks = 25
  let randMitte = 95
  let breite = 160
  // let lange = 200




  header(doc, person, randLinks, seite, vorschau, false)
  
  printData.forEach(eintrag => {
    if (randOben > 240) {
      randOben = 65
      doc.addPage()
      seite++
      header(doc, person, randLinks, seite, vorschau, false)
    }
    if (eintrag.art === 'newPage') {
      randOben = 65
      doc.addPage()
      seite++
      header(doc, person, randLinks, seite, vorschau, false)
    }
    if (eintrag.art === 'meta') {
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(9)
      doc.setFont('Roboto', 'normal')
      doc.text(eintrag.text, randLinks + breite, randOben, { align: "right" })
      randOben += 9
    }
    if (eintrag.art === 'header') {
      randOben += 9
      doc.setTextColor(21, 59, 100)
      doc.setFontSize(18)
      doc.setFont('Oswald', 'normal')
      doc.text(eintrag.text, randLinks, randOben)
      doc.setFillColor(21, 59, 100)
      doc.rect(randLinks, randOben + 1, breite, .5, 'F')
      randOben += 9
    }
    if (eintrag.art === 'titel') {
      randOben += 7
      doc.setTextColor(21, 59, 100)
      doc.setFontSize(16)
      doc.setFont('Roboto', 'bold')
      doc.text(eintrag.text, randLinks, randOben)
      randOben += 7
    }
    if (eintrag.art === 'text') {
      doc.setFontSize(11)
      doc.setFont('Roboto', 'bold')
      doc.setTextColor(21, 59, 100)
      doc.text(eintrag.beschreibung, randLinks, randOben)
      doc.setFont('Roboto', 'normal')
      doc.setTextColor(0, 0, 0)
      doc.text(eintrag.text, randMitte, randOben)
      randOben += 7
    }
    if (eintrag.art === 'text_lang') {
      if (randOben > 270) {
        randOben = 65
        doc.addPage()
        seite++
        header(doc, person, randLinks, seite, vorschau, false)
      }
      doc.setFontSize(11)
      doc.setFont('Roboto', 'bold')
      if (eintrag.beschreibung) {
        doc.setTextColor(21, 59, 100)
        doc.text(eintrag.beschreibung, randLinks, randOben)
        randOben += 7
      }
      
      let lines = doc.splitTextToSize(eintrag.text, breite)

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (randOben > 270) {
          randOben = 65
          doc.addPage()
          seite++
          header(doc, person, randLinks, seite, vorschau, false)
        }
        doc.setFontSize(11)
        doc.setTextColor(0, 0, 0)
        doc.setFont('Roboto', 'normal')
        doc.text(line, randLinks, randOben)
        randOben += 6
      }
    }
    if (eintrag.art === 'bool') {
      doc.setFontSize(11)
      doc.setFont('Roboto', 'bold')
      doc.setTextColor(21, 59, 100)
      doc.text(eintrag.beschreibung, randLinks, randOben)
      randOben += 7
      doc.setFont('Roboto', 'bold')
      doc.rect(randLinks, randOben, .2, 12, 'F')
      let a = 0
      let counter = 0
      eintrag.text.forEach(item => {
        a += breite / eintrag.text.length / 2
        doc.setFontSize(9)
        doc.setTextColor(21, 59, 100)
        doc.text(eintrag.prop[counter], randLinks + a, randOben + 4, { align: "center" })
        if (item) {
          doc.setFontSize(15)
          doc.setTextColor(0, 0, 0)
          doc.text('x', randLinks + a, randOben + 10, { align: "center" })
        }
        a += breite / eintrag.text.length / 2
        doc.rect(randLinks + a, randOben, .2, 12, 'F')
        counter++
      })
      doc.setFillColor(21, 59, 100)
      doc.rect(randLinks, randOben, breite, .2, 'F')
      randOben += 5
      doc.rect(randLinks, randOben, breite, .2, 'F')
      randOben += 7
      doc.rect(randLinks, randOben, breite, .2, 'F')
      doc.setFontSize(11)
      doc.setFont('Roboto', 'normal')
      doc.setTextColor(0, 0, 0)
      // doc.text(eintrag.text, randMitte, randOben)
      randOben += 7
    }
  })

  // **********************
  // Anhänge
  // **********************
  doc.setTextColor(21, 59, 100)
  doc.setFontSize(11)
  doc.setFont('Roboto', 'bold')
  bewerbung.anhang.forEach(item => {
    doc.textWithLink(item.filename, randLinks, randOben, { url: item.path });
    randOben += 7
  })
  bewerbung.anhang_sonstige.forEach(item => {
    doc.textWithLink(item.filename, randLinks, randOben, { url: item.path });
    randOben += 7
  })

  // **********************
  // Kontaktseite
  // **********************
  randOben = 65
  doc.addPage()
  seite++
  header(doc, person, randLinks, seite, vorschau, true)
  doc.setFontSize(18)
  doc.setFont('Oswald', 'normal')
  doc.setTextColor(21, 59, 100)
  doc.text('Kontakt', randLinks, randOben)
  doc.setFillColor(21, 59, 100)
  doc.rect(randLinks, randOben + 1, breite, .5, 'F')
  randOben += 14
  doc.setFontSize(11)
  doc.setFont('Roboto', 'normal')
  doc.setTextColor(0, 0, 0)
  doc.text('Sie haben weitere Fragen? Dann wenden Sie sich gerne an uns.', randLinks, randOben)
  randOben += 14
  doc.text('Fragen zum Award, zum Konzept, der Planung und Durchführung, zur Jury,', randLinks, randOben)
  randOben += 7
  doc.text('zum Bewerbungsformular und zur Bewertung bitte an:', randLinks, randOben)
  randOben += 14
  doc.setFont('Roboto', 'bold')
  doc.text('Senat der Wirtschaft Deutschland e.V.', randLinks, randOben)
  randOben += 7
  doc.text('Svenja Reimer', randLinks, randOben)
  doc.setFont('Roboto', 'normal')
  randOben += 7
  doc.text('sdg-award@senat-deutschland.de', randLinks, randOben)
  randOben += 14
  doc.setFont('Roboto', 'bold')
  doc.text('Büro Bonn', randLinks, randOben)
  doc.setFont('Roboto', 'normal')
  randOben += 7
  doc.text('Adenauerallee 206', randLinks, randOben)
  randOben += 7
  doc.text('53113 Bonn', randLinks, randOben)
  randOben += 7
  doc.text('Telefon: +49 228 915605-0', randLinks, randOben)
  randOben += 7
  doc.text('Telefax: +49 228 915605-199', randLinks, randOben)
  randOben += 14
  doc.setFont('Roboto', 'bold')
  doc.text('Büro Berlin', randLinks, randOben)
  doc.setFont('Roboto', 'normal')
  randOben += 7
  doc.text('Haus der Bundespressekonferenz', randLinks, randOben)
  randOben += 7
  doc.text('Schiffbauerdamm 40', randLinks, randOben)
  randOben += 7
  doc.text('10117 Berlin', randLinks, randOben)
  randOben += 7
  doc.text('Telefon: +49 30 4010 995-0', randLinks, randOben)
  randOben += 7
  doc.text('Telefax: +49 304010 995-19', randLinks, randOben)
  randOben += 7
  doc.save(bewerbung.preis + ' Bewerbungsunterlagen_' + person.institution.replace('.', '') + '.pdf');
  console.log(!vorschau)
  if (!vorschau) {
    let name = person.institution.replace('.', '') + '_' + item.anmeldeID + '.pdf'
    console.log(name)
    // return 'bewerbungPDF/' + name
    return firebase.storage().ref('bewerbungPDF/' + name).put(doc.output('blob'))
      .then(() => {
        console.log('abc')
        return 'bewerbungPDF/' + name
      })
      .catch(error => {
        console.log(error)
        const errorText = '#mD383: ' + error
        throw new Error({
          status: 'error',
          error: errorText
        })
      })
  }
}