<!-- eslint-disable -->
<template>
  <v-container fluid>
    <v-row class="pa-2">
      <v-col cols="12">
        <v-toolbar dense flat rounded>
          <v-toolbar-title>Auswertung</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-text-field
              v-model='search'
              append-icon='mdi-magnify'
              label='Suchen'
              single-line
              solo
              flat
              hide-details
              clearable
              clear-icon="mdi-close"
            ></v-text-field>
          </v-toolbar-items>
        </v-toolbar>
      </v-col>
      <v-col cols="12">
        
        <v-card class="mb-2 pa-2">
          <v-tabs v-model="tab" fixed-tabs>
            <v-tab v-for="(item, key, index) in bewerbungen" :key="index">{{ key }} ({{ item.length }})</v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, key, index) in bewerbungen" :key="index">
              <v-data-table
                dense
                :height="'calc(100vh - 400px)'"
                :headers="headers"
                :items="item"
                :search="search"
                fixed-header
                hide-default-footer
                :hide-default-header="$vuetify.breakpoint.xsOnly"
                :items-per-page="-1"
                no-data-text="Keine Bewerbungen vorhanden"
                :expanded.sync="expanded[key]"
                single-expand
                show-expand
                expand-icon="mdi-chevron-down"
              >
                <template v-slot:item.index="{ index }">
                  <td class="text-center" width="20px">{{ index + 1 }}.</td>
                </template>
                <template v-slot:item.dPunkte="{ item }">
                  <td width="120px" class="text-center">{{ item.dPunkte }}</td>
                </template>
                <template v-slot:item.punkte="{ item }">
                  <td width="200px">
                    <v-chip :class="getColor(item, value)" label small v-for="(value, index) in item.punkte" :key="index" class="mr-1">{{ value }}</v-chip>
                  </td>
                </template>
                <template v-slot:item.anmerkung="{ item }">
                  <td width="20px">
                    <v-icon v-if="item.anmerkung" color="primary">mdi-chat</v-icon>
                  </td>
                </template>
                <template v-slot:item.sonderpreis="{ item }">
                  <!-- <td width="140px" :class="item.sonderpreis / item.anzahl >= 0.50 ? 'green' : ''">{{ item.sonderpreis }} von {{ item.anzahl }}</td> -->
                  <td width="140px">
                    <v-progress-linear
                      :value="item.sonderpreis / item.anzahl * 100"
                      color="green"
                      height="25"
                    >
                      {{ item.sonderpreis }} von {{ item.anzahl }}
                    </v-progress-linear>
                  </td>
                </template>
                <!-- <template v-slot:item.unidopreis="{ item }">
                  <td width="140px">
                    <v-progress-linear
                      :value="item.unidopreis / item.anzahl * 100"
                      color="green"
                      height="25"
                    >
                      {{ item.unidopreis }} von {{ item.anzahl }}
                    </v-progress-linear>
                  </td>
                </template> -->
                <template v-slot:item.export="{ item }">
                  <td class="px-0" width="20px"><v-btn v-if="item.pdf_merge" icon @click="pdfHerunterladen(item)"><v-icon>mdi-printer</v-icon></v-btn></td>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-simple-table dense class="my-4">
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th class="px-1 text-center" colspan="13">Punkte</th>
                          <th></th>
                        </tr>
                        <tr>
                          <th>Jury-Mitglied</th>
                          <th class="text-center">Sonderpreis</th>
                          <!-- <th class="text-center">Unidopreis</th> -->
                          <th class="px-1 text-center">1.1</th>
                          <!-- <th class="px-1 text-center">1.1*</th> -->
                          <th class="px-1 text-center">1.2</th>
                          <th class="px-1 text-center">1.3</th>
                          <th class="px-1 text-center">2.1</th>
                          <th class="px-1 text-center">3.1</th>
                          <th class="px-1 text-center">4.1</th>
                          <th class="px-1 text-center">4.2</th>
                          <th class="px-1 text-center">5.1</th>
                          <th class="px-1 text-center">5.2</th>
                          <th class="px-1 text-center">6.1</th>
                          <th class="px-1 text-center">7.1</th>
                          <th class="px-1 text-center">7.2</th>
                          <th class="px-1 text-center">7.3</th>
                          <th>Anmerkung Sonderpreis</th>
                          <!-- <th>Anmerkung Unidopreis</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(val, index2) in item.bewertungen" :key="index2">
                          <td width="150px">{{ val.anmeldeID | user(personen) }}</td>
                          <td width="40px" class="text-center"><v-icon v-if="val.data.sonderpreis" color="green">mdi-check-circle</v-icon></td>
                          <!-- <td width="40px" class="text-center"><v-icon v-if="val.data.unidopreis" color="green">mdi-check-circle</v-icon></td> -->
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[0] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[2] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[3] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[4] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[5] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[6] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[7] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[8] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[9] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[10] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[11] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[12] }}</v-chip></td>
                          <td width="20px" class="px-1 text-center"><v-chip label small class="mr-1">{{ val.data.punkte[13] }}</v-chip></td>
                          <td>{{ val.data.anmerkung }}</td>
                          <!-- <td>{{ val.data.unidoanmerkung }}</td> -->
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showError"
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'"
      scrollable
      persistent
    >
      <v-card>
        <v-system-bar class="warning" height="12"></v-system-bar>
        <v-card-text class="mt-6 text-center">
          {{ $t('mainDashboard.dialogerrorText') }} <br><a href="mailto:portal@german-sdg-award.de">portal@german-sdg-award.de</a>
          <br><br>
          {{ error }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="showError = false">{{ $t('schliessen') }}</v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'auswertungPage',
    data() {
      return {
        showBewertung: false,
        tab: 0,
        expanded: [],
        bewertungData: {},
        loading: false,
        search: '',
        headers: [
          { number: '0', text: '', align: 'left', value: 'index' },
          { number: '1', text: 'Projekt', align: 'left', value: 'institution' },
          { number: '2', text: '⌀ Punkte', align: 'left', value: 'dPunkte' },
          { number: '3', text: 'Punkte', align: 'left', value: 'punkte' },
          { number: '4', text: 'Sonderpreis', align: 'left', value: 'sonderpreis' },
          { number: '5', text: '', align: 'left', value: 'anmerkung' },
          // { number: '4', text: 'Unidopreis', align: 'left', value: 'unidopreis' },
          { number: '6', text: '', align: 'left', value: 'export' },
          { number: '7', text: '', value: 'data-table-expand' }
        ],
        showError: false,
        error: null
      }
    },
    computed: {
      user () {
        return this.$store.state.user.user
      },
      personen () {
        return this.$store.state.personen.personen
      },
      bewertungen () {
        console.log(this.$store.state.bewertungen.bewertungen)
        return this.$store.state.bewertungen.bewertungen
      },
      bewerbungen() {
        let bewerbungen = this.$store.state.bewerbungen.bewerbungen.filter(item => item.status === 'Abgesendet');
        bewerbungen.forEach(bewerbung => {
          let person = this.$store.state.personen.personen.find(item => item.anmeldeID === bewerbung.anmeldeID)
          if (person) {
            bewerbung.institution = person.data.institution
            if (person.data.titel) {
              bewerbung.ansprechpartner = person.data.titel + ' ' + person.data.nachname + ', ' + person.data.vorname
            } else {
              bewerbung.ansprechpartner = person.data.nachname + ', ' + person.data.vorname
            }
          } else {
            bewerbung.institution = 'Keine Angabe'
            bewerbung.ansprechpartner = 'Keine Angabe'
          }
          let bewertungen = this.bewertungen.filter(item => item.bewerbungID === bewerbung.id)
          let bewertungenAnz = 0
          bewerbung.bewertungen = bewertungen
          bewerbung.dPunkte = 0
          bewerbung.punkte = [] 
          bewerbung.sonderpreis = 0
          bewerbung.unidopreis = 0
          bewerbung.anzahl = bewertungen.length
          bewerbung.anmerkung = false
          if (bewertungen.length > 0) {
            bewertungen.forEach(item => {
              if (item.data.punkte.filter(punkt => punkt !== null && !isNaN(punkt)).length === 13) {
                let punkte = item.data.punkte.filter(punkt => punkt !== null && !isNaN(punkt)).reduce((sum, punkt) => sum + punkt, 0)
                bewerbung.dPunkte += punkte
                if (punkte > 0) {
                  bewertungenAnz++
                }
                bewerbung.punkte.push(punkte)
                if (item.data.sonderpreis) {
                  bewerbung.sonderpreis += 1
                }
                if (item.data.unidopreis) {
                  bewerbung.unidopreis += 1
                }
                if (item.data.anmerkung) {
                  bewerbung.anmerkung = true
                }
              }
            })
            bewerbung.punkte.sort((a, b) => a - b)
            bewerbung.punkteDifferenz = bewerbung.punkte.length > 1 ? bewerbung.punkte[bewerbung.punkte.length - 1] - bewerbung.punkte[0] : 0;
            bewerbung.dPunkte = bewertungenAnz > 0 ? (bewerbung.dPunkte / bewertungenAnz).toFixed(1) : '-'
          }
        })
        // Gruppierung nach item.data.kategorie
        const groupedBewerbungen = bewerbungen.reduce((result, item) => {
          const kategorie = item.data.kategorie
          if (!result[kategorie]) {
            result[kategorie] = []
          }
          result[kategorie].push(item)
          return result
        }, {})
        for (const kategorie in groupedBewerbungen) {
          groupedBewerbungen[kategorie].sort((a, b) => b.dPunkte - a.dPunkte)
        }
        console.log(groupedBewerbungen)
        return groupedBewerbungen
      }
    },
    methods: {
      getColor(item, value) {
        if (Math.abs(item.dPunkte - value) > 15) {
          return 'red white--text'
        }
        if (Math.abs(item.dPunkte - value) > 10) {
          return 'orange white--text'
        }
      },
      pdfHerunterladen (item) {
        this.loading = true
        return this.$firebase.storage().ref(item.pdf_merge).getDownloadURL()
          .then(url => {
            const link = document.createElement('a')
            link.href = url
            link.target = '_blank'
            link.download = item.data.preis + ' Bewerbungsunterlagen_' + item.anmeldeID + '.pdf'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.loading = false
          })
          .catch((error) => {
            this.error = '#mD400: ' + error
            this.showError = true
            this.loading = false
            throw error;
          });
      }
    }
  }
</script>

<style>
</style>
